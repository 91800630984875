import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import UserList from "../../Components/UserList/UserList";
import NoCustomerImg from "./../../images/no-customer.png";
import Stack from "@mui/material/Stack";
import { useQuery } from "react-query"
import { getSupplier, getSupplierPayment, setSupplier } from "./ApiCalls";
import { Heading, AddButton } from "./../../Components/Common";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { RightDrawer } from "../../Helper/RightDrawer/RightDrawer";
import { Skeleton } from "@mui/material";
import { getLoginCheck } from "./../../Auth/Authentication";
import { useNavigate } from "react-router-dom";
import "./Supplier.css";

export default function Supplier() {

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, seterror] = useState(false);
  const [code, setCode] = useState(0);
  const [number, setNumber] = useState(0);

  const SupplierList = useQuery("SupplierList", () => {
    return getSupplierPayment()
  }, {})

  console.log(SupplierList.data)

  useEffect(() => {
    // sendMessage()
    getLoginCheck()
      .then(response => {
        if (response.data !== 'Session found') {
          navigate('/login');
        }
      }).catch(err => {
        console.log(err);
      });
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    const supplier_no = code + " " + number;
    var data = new FormData(event.currentTarget);
    setSupplier(data, supplier_no)
      .then((response) => {
        if (response.status === 200) {
          setOpen(false);
          event.target.reset();
          SupplierList.refetch()
          seterror(false);
        } else {
          seterror(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <SearchBar />
      <List
        className="cus-tabpanel"
        sx={{ width: "100%", bgcolor: "background.paper", pt: 0, pb: 0 }}
      >
        {SupplierList.isLoading ?
          <Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton animation="wave" variant="rectangular" width="80%" height={20} sx={{ mt: 1 }} />
            </Box>
            <Skeleton animation="wave" variant="rectangular" sx={{ mt: 1 }} />
          </Box>
          :
          <React.Fragment>
            {SupplierList.data !== undefined ?
              SupplierList.data.length !== 0 ?
                SupplierList.data.map((row, id) => {
                  return (
                    <Box key={id}>
                      <UserList
                        redirect={"/chatpages/" + row.customer_id}
                        Amount={row.amounts}
                        name={row.name}
                        date={row.create_dt}
                      />
                    </Box>
                  )
                })
                :
                <Box style={{ textAlign: "center" }}>
                  <img
                    loading="lazy"
                    src={NoCustomerImg}
                    alt="No Payment"
                    width="80%"
                    style={{ margin: "20px 0" }}
                  />
                  <Heading heading="Start your digital ledger by adding a supplier" css={{ fontSize: "18px", fontWeight: "600" }} />
                </Box>
              : null
            }
          </React.Fragment>
        }
      </List >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          position: "fixed",
          bottom: "5rem",
          right: "12px",
          padding: "7px 14px",
          borderRadius: "26px",
        }}
      >
        <AddButton primary="Add Supplier" onclicknext={() => setOpen(true)} />
      </Stack>
      <RightDrawer
        Title="Add Supplier"
        open={open}
        InputUsername="supplier_name"
        InputEmail="supplier_email"
        error={error}
        ErrorMsg={"Supplier Already Exists"}
        CloseRightDrawer={() => setOpen(false)}
        SubmitFrom={onSubmit}
        BtnText="Add Supplier"
        ContactFiledChange={(value, data, event, formattedValue) =>
          setCode(data.dialCode) + setNumber(value.slice(data.dialCode.length))}
      />
    </React.Fragment>
  );
}