import Axios from "axios";
import { API_URL } from "../../App";

export const getCustomerOverview = async () => {
    try {
        const response = await Axios.post(`${API_URL}/account/customer-overview`);
        return response;
    } catch (err) {

        return err;

    }
}

export const getcustomerDetails = async () => {
    try {
        const response = await Axios.post(`${API_URL}/account-statement/get-all-customer`);
        return response;
    } catch (err) {
        return err;
    }
};
