import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import PARLogo from "./../images/par-logo.png";
import { Font } from "@react-pdf/renderer";

// Create styles
Font.register({
  family: "AntonFamily",
});

const styles = StyleSheet.create({
  body: {
    margin: 0,
    padding: 0,
    color: "white",
  },
  image: {
    // marginVertical: 5,
    height: "125px",
  },

  section: {
    color: "black",
    // textAlign: "center",
    margin: 10,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    // maxWidth: 800,
  },
  sectionHeader: {
    display: "flex",
    flexDirection: "row",
    // alignItems: "center",
    justifyContent: "space-around",
    // flexFlow: "row wrap",
    gap: "20px",
    // flexGrow: "2",
    width: "100%",
    height: "80px",
  },
  divider: {
    height: "50px",
    width: "300px",
    backgroundColor: "#eab45e",
  },
  dividerTwo: {
    height: "50px",
    width: "100px",
    backgroundColor: "#eab45e",
  },
  textDivider: {
    fontSize: "40px",
    textTransform: "uppercase",
    letterSpacing: "1px",
    color: "#949494",
    fontWeight: "ultrabold",
    // "& :before": {
    //   content: "",
    //   height: "1px",
    //   backgroundColor: "#eab45e",
    //   flexGrow: 1,
    //   marginRight: "16px",
    // },
    // "& :after": {
    //   content: "",
    //   height: "1px",
    //   backgroundColor: "#eab45e",
    //   flexGrow: 1,
    //   marginLeft: "16px",
    // },
  },
  customerSpace: {
    marginHorizontal: 50,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  customerInfo: {
    display: "flex",
    flexDirection: "row",
    // flexGrow: 1,
    // justifyContent: "space-between",
    gap: "100px",
    height: "80px",
  },
  customerAddress: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    // paddingRight: "200px",
    width: "300px",
  },
  customerDate: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    gap: "10px",
    width: "100px",
  },
  label: { color: "black", fontSize: "18px", fontWeight: 800 },
  labelValue: { color: "black", fontSize: "14px", fontWeight: "light" },
  transactionInfo: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "4px",
    width: "150px",
  },
  transactionBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: "5px",
  },
  transactionClientNumber: {
    color: "black",
    fontWeight: 800,
    fontSize: "16px",
  },
  lineDivider: {
    marginTop: 2,
    height: "1px",
    backgroundColor: "black",
    width: "500px",
  },
  verticalDivider: {
    color: "black",
    backgroundColor: "black",
    borderRight: "1px solid black",
  },
  holderDetails: {
    display: "flex",
    flexDirection: "row",
    gap: "30px",
  },
  holderName: {
    color: "black",
    fontWeight: 800,
    fontSize: "20px",
    alignSelf: "center",
  },
  holderAmount: {
    backgroundColor: "#eab45e",
    color: "white",
    padding: 15,
    width: "200px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontFamily: "AntonFamily",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "black",
    borderTop: "4px solid #eab45e",
    height: "60px",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    justifyContent: "center",
  },
  footerTextMain: {
    display: "flex",
    flexDirection: "row",
    gap: "2px",
    justifyContent: "center",
  },
  footerText: {
    color: "black",
    fontSize: "14px",
    alignSelf: "center",
  },
  footerCopyright: {
    color: "#585858",
    fontSize: "10px",
    alignSelf: "center",
  },
});

const PDFFile = () => {
  const pageColors = ["#fff"];

  const pages = [{ text: "INVOICE", image: PARLogo }];

  return (
    <Document>
      {pages.map((page, index) => {
        return (
          <Page
            key={index}
            style={{ ...styles.body, backgroundColor: pageColors[index] }}
          >
            <View style={styles.section}>
              {/* <Text style={styles.header} fixed>{`${index} Page`}</Text> */}
              <Image style={styles.image} src={page.image} />
              {/* <Divider>INVOICE</Divider> */}
            </View>
            <View style={styles.sectionHeader}>
              <View style={styles.divider}></View>

              <Text style={styles.textDivider}>{`${page.text}`}</Text>

              <View style={styles.dividerTwo}></View>
            </View>
            <View style={styles.customerSpace}>
              <View style={styles.customerInfo}>
                <View style={styles.customerAddress}>
                  <Text style={styles.label}>
                    Address: <br />
                  </Text>
                  <Text style={{ ...styles.labelValue, fontSize: "16px" }}>
                    001- Opposite, Iris Park, Jogeshwari West, Mumbai,
                    Maharashtra
                  </Text>
                </View>
                <View style={styles.customerDate}>
                  <Text style={styles.label}>Date:</Text>
                  <Text
                    style={{
                      ...styles.labelValue,
                      fontSize: "16px",
                      // paddingTop: 1,
                    }}
                  >
                    22/05/22
                  </Text>
                </View>
              </View>
              <View style={styles.transactionInfo}>
                <View style={styles.transactionBox}>
                  <Text style={styles.transactionClientNumber}>
                    Cash Receipt:{" "}
                  </Text>
                  <Text style={styles.transactionClientNumber}>5555</Text>
                </View>
                <View style={styles.transactionBox}>
                  <Text style={styles.transactionClientNumber}>PO Box: </Text>
                  <Text style={styles.transactionClientNumber}>5454868645</Text>
                </View>
                <View style={styles.transactionBox}>
                  <Text style={styles.transactionClientNumber}>TRN: </Text>
                  <Text style={styles.transactionClientNumber}>112265</Text>
                </View>
              </View>
              <View style={styles.lineDivider}></View>
              <View style={styles.lineDivider}></View>
              <View style={styles.holderDetails}>
                <Text style={styles.holderName}>Holder Name</Text>
                <View style={styles.verticalDivider}></View>
                <Text style={styles.holderName}>Keval</Text>
                <View style={styles.verticalDivider}></View>
                <View style={styles.holderAmount}>
                  <Text style={{ ...styles.label, color: "white" }}>
                    Amount AED 500
                  </Text>
                </View>
              </View>
              <View style={styles.lineDivider}></View>
              <View style={styles.lineDivider}></View>
              {/* Cash Details */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  marginVertical: 2,
                }}
              >
                <Text style={{ ...styles.label, fontSize: "16px" }}>
                  Cash Receive From Krishna of AED 5000
                </Text>
                <Text style={{ ...styles.label, fontSize: "16px" }}>
                  In words Five Thousand Only
                </Text>
              </View>
              <View style={styles.lineDivider}></View>
              <View
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <Text style={{ ...styles.label, fontSize: "16px" }}>
                  Description:{" "}
                </Text>
                <Text style={{ ...styles.label, fontSize: "16px" }}>
                  Detail filled by User during receiving the payment{" "}
                </Text>
              </View>
            </View>
            <View style={styles.footer}>
              <View style={styles.footerTextMain}>
                <Text style={styles.footerText}>Phone: 555-555-5555</Text>
                <View
                  style={{
                    ...styles.verticalDivider,
                    height: "15px",
                    alignSelf: "center",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text style={styles.footerText}>Email: par@example.com</Text>
                <View
                  style={{
                    ...styles.verticalDivider,
                    height: "15px",
                    alignSelf: "center",
                    marginHorizontal: 5,
                  }}
                ></View>
                <Text style={styles.footerText}>
                  Website: www.paraddress.com
                </Text>
              </View>
              <View>
                <Text style={styles.footerCopyright}>
                  Document Generated through PAR. No signature required
                </Text>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default PDFFile;
