import * as React from "react";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from '@mui/icons-material/Logout';
import Container from "@mui/material/Container";
import { ButtonSetting, Heading, Description } from "../../Components/Common";
import StarsIcon from "@mui/icons-material/Stars";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import Button from "@mui/material/Button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { UserHeader } from "./../../Components/SubHeader/SubHeader";
import { useNavigate } from "react-router-dom";
import { LogoutUser } from "./ApiCalls.jsx";
import { useQuery } from "react-query";
import { API_URL } from "../../App";
import { getUserDetails } from "./../../Auth/Authentication";
import Cookies from 'react-cookies'
import "./Setting.css";

export default function Setting() {

  const navigate = useNavigate();

  const UserDetails = useQuery("CPUserDetails", () => {
    return getUserDetails()
  }, {})

  const logout_user = () => {
    LogoutUser()
      .then((response) => {
        Cookies.remove('user_id');
        navigate('/login');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <React.Fragment>
      {!UserDetails.isLoading ?
        <UserHeader
          UserName={UserDetails.data.data[0].person_name}
          UserProfileImg={`${API_URL}/Upload/UserProfile/${UserDetails.data.data[0].profile_img}`}
        />
        :
        <Box sx={{ padding: "10px 20px", display: "flex", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
          <Skeleton animation="wave" variant="circular" width={40} height={40} />
          <Skeleton width="60%" sx={{ ml: 2 }} />
        </Box>
      }
      <Container maxWidth="sm" sx={{ mt: UserDetails.isLoading ? 3 : 13 }}>
        <List className="setting-list-card" sx={{ textDecoration: "none " }} component={Link} to="/profile">
          <ButtonSetting primary="Profile" icon={<PersonOutlineIcon />} />
        </List>
        {/* <List className="setting-list-card" sx={{ textDecoration: "none " }} component={Link} to="http://parwebsite.rabs.support/" target="_blank">
          <ButtonSetting primary="PAR Subscription Plan" icon={<StarsIcon />} />
        </List> */}
        {/* <List className="setting-list-card" sx={{ textDecoration: "none " }} component={Link} to="http://parwebsite.rabs.support/">
          <ButtonSetting primary="How to use PAR ?" icon={<HelpOutlineIcon />} />
        </List> */}
        {/* <List className="setting-list-card" sx={{ textDecoration: "none " }} component={Link} to="https://parwebsite.rabs.support/">
          <ButtonSetting primary="About PAR" icon={<InfoOutlinedIcon />} />
        </List> */}
        {/* <List className="setting-list-card" sx={{ textDecoration: "none " }} component={Link} to="/privacy-policy">
          <ButtonSetting primary="Privacy Policy & Security" icon={<HelpOutlineIcon />} />
        </List> */}
        <List className="setting-list-card" sx={{ textDecoration: "none " }} onClick={logout_user}>
          <ButtonSetting primary="Log out" icon={<LogoutIcon />} />
        </List>

        {/* <Box sx={{ textAlign: "center" }}>
          <Description description="Version v2.90.3 (1053)" />
          <Heading heading="Made in UAE" />
        </Box> */}
        <Stack>
          <Button
            variant="contained"
            sx={{ width: "100%", margin: "auto" }}
            startIcon={<CallOutlinedIcon />}
          >
            Call Customer Care
          </Button>
        </Stack>
      </Container>
    </React.Fragment>
  );
}