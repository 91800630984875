import React, { useEffect } from "react";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Link } from "react-router-dom";
import { getCustomerOverview, getcustomerDetails } from "./ApiCalls";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Skeleton from "@mui/material/Skeleton";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { Chart } from "react-google-charts";
import logo from "../../images/par-logo.png";
import { useQuery } from "react-query";
import { getLoginCheck, getUserDetails } from "./../../Auth/Authentication";
import { useNavigate } from "react-router-dom";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import "./Dashboard.css";
import { API_URL } from "../../App";
import { Description, Heading } from "../../Components/Common";
import PDFFile from "../../Components/PDFFile";

const Dashboard = () => {
  const navigate = useNavigate();

  const UserDetails = useQuery(
    "CPUserDetails",
    () => {
      return getUserDetails();
    },
    {}
  );

  useEffect(() => {
    getLoginCheck()
      .then((response) => {
        if (response.data !== "Session found") {
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const CustomerOverview = useQuery(
    "CustomerOverviewData",
    () => {
      return getCustomerOverview();
    },
    {}
  );

  const AllCustomer = useQuery(
    "AllCustomerData",
    () => {
      return getcustomerDetails();
    },
    {}
  );

  const CustomerIDArr = [];
  const TotalCustomerReceivedArr = [];
  const TotalCustomerGivenArr = [];
  const SupplierIDArr = [];
  const TotalSupplierReceivedArr = [];
  const TotalSupplierGivenArr = [];

  if (!CustomerOverview.isLoading && !AllCustomer.isLoading) {
    CustomerOverview.data.data.map((row) => {
      var CustomerName = "";
      AllCustomer.data.data
        .filter((data) => row.customer_id === data.customer_id)
        .map((data, id) => {
          CustomerName = data.customer_name;
        });

      if (row.type === "customer" && CustomerName !== "") {
        if (CustomerIDArr.indexOf(row.customer_id) === -1) {
          CustomerIDArr.push(row.customer_id);
        }
      }
    });

    CustomerOverview.data.data.map((row) => {
      var CustomerName = "";
      AllCustomer.data.data
        .filter((data) => row.customer_id === data.customer_id)
        .map((data, id) => {
          CustomerName = data.customer_name;
        });

      if (row.type === "customer" && CustomerName !== "") {
        if (row.status === "received" && row.delete === "No") {
          TotalCustomerReceivedArr.push(parseInt(row.amount));
        }
      }
    });
    CustomerOverview.data.data.map((row) => {
      var CustomerName = "";
      AllCustomer.data.data
        .filter((data) => row.customer_id === data.customer_id)
        .map((data, id) => {
          CustomerName = data.customer_name;
        });

      if (row.type === "customer" && CustomerName !== "") {
        if (row.status === "given" && row.delete === "No") {
          TotalCustomerGivenArr.push(parseInt(row.amount));
        }
      }
    });

    CustomerOverview.data.data.map((row) => {
      var CustomerName = "";
      AllCustomer.data.data
        .filter((data) => row.customer_id === data.customer_id)
        .map((data, id) => {
          CustomerName = data.customer_name;
        });

      if (row.type === "supplier" && CustomerName !== "") {
        if (SupplierIDArr.indexOf(row.customer_id) === -1) {
          SupplierIDArr.push(row.customer_id);
        }
      }
    });
    CustomerOverview.data.data.map((row) => {
      var CustomerName = "";
      AllCustomer.data.data
        .filter((data) => row.customer_id === data.customer_id)
        .map((data, id) => {
          CustomerName = data.customer_name;
        });

      if (row.type === "supplier" && CustomerName !== "") {
        if (row.status === "received" && row.delete === "No") {
          TotalSupplierReceivedArr.push(parseInt(row.amount));
        }
      }
    });
    CustomerOverview.data.data.map((row) => {
      var CustomerName = "";
      AllCustomer.data.data
        .filter((data) => row.customer_id === data.customer_id)
        .map((data, id) => {
          CustomerName = data.customer_name;
        });

      if (row.type === "supplier" && CustomerName !== "") {
        if (row.status === "given" && row.delete === "No") {
          TotalSupplierGivenArr.push(parseInt(row.amount));
        }
      }
    });
  }

  const TotalCustomerReceiveAmt = parseInt(
    TotalCustomerReceivedArr.reduce((partialSum, a) => partialSum + a, 0)
  );
  const TotalCustomerGivenAmt = parseInt(
    TotalCustomerGivenArr.reduce((partialSum, b) => partialSum + b, 0)
  );

  const TotalSupplierReceiveAmt = parseInt(
    TotalSupplierReceivedArr.reduce((partialSum, a) => partialSum + a, 0)
  );
  const TotalSupplierGivenAmt = parseInt(
    TotalSupplierGivenArr.reduce((partialSum, b) => partialSum + b, 0)
  );

  const data = [
    ["Task", "Hours per Day"],
    ["Customer Payment", TotalCustomerGivenAmt < 0 ? 0 : TotalCustomerGivenAmt],
    ["Supplier Payment", TotalSupplierGivenAmt < 0 ? 0 : TotalSupplierGivenAmt],
    // ["Supplier Net Balance", (TotalSupplierGivenAmt - TotalSupplierReceiveAmt) < 0 ? 0 : TotalSupplierGivenAmt - TotalSupplierReceiveAmt],
    // ["Customer Net Balance", (TotalCustomerReceiveAmt - TotalCustomerGivenAmt) < 0 ? 0 : TotalCustomerReceiveAmt - TotalCustomerGivenAmt],
  ];

  const options = {
    // title: "Total Amount Receiver & Payable",
    bold: true,
    fontSize: "12",
    is3D: true,
    marginBottom: "20",
    sliceVisibilityThreshold: 0.2, // 20%
    hAxis: {
      title: "Weekly",
      minValue: 0,
    },
    legend: "none",
  };

  return (
    <>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Container
          maxWidth="xl"
          sx={{
            bgcolor: "#fff",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <Toolbar disableGutters>
            {/* <Typography
              variant="h6"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              PAR
            </Typography> */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <img
                src={logo}
                width="80"
                height="100%"
                alt="Logo"
                style={{ marginLeft: 1, padding: "5px" }}
              />
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton sx={{ p: 0 }} component={Link} to={"/profile"}>
                  {!UserDetails.isLoading ? (
                    <Avatar
                      alt={UserDetails.data.data[0].person_name}
                      src={`${API_URL}/Upload/UserProfile/${UserDetails.data.data[0].profile_img}`}
                    />
                  ) : (
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {!CustomerOverview.isLoading ? (
        <>
          <List
            sx={{
              py: 0,
              margin: "12px 10px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              color: "#3e3e3e !important",
            }}
          >
            <ListItem
              sx={{ display: "grid" }}
              button
              component={Link}
              to={"/account-statement/customer"}
            >
              <h4 className="account-inners">
                <ImportContactsIcon
                  sx={{ verticalAlign: "middle", mr: 2, color: "#1976d2" }}
                />
                Net Balance
                <KeyboardArrowRightIcon
                  sx={{
                    verticalAlign: "bottom",
                    right: "15px",
                    position: "absolute",
                  }}
                />
              </h4>
              <h3 className="account-inners-main">
                Customer Ledger
                <span
                  className="account-inner-icon"
                  style={{
                    color:
                      TotalCustomerReceiveAmt - TotalCustomerGivenAmt > 0
                        ? "#008000 "
                        : "#ff0000",
                  }}
                >
                  {"AED " +
                    (TotalCustomerReceiveAmt - TotalCustomerGivenAmt)
                      .toString()
                      .replace("-", "")}
                </span>
              </h3>
              <h4 className="account-inners">
                <PersonOutlineIcon sx={{ verticalAlign: "sub", mr: 1 }} />
                {CustomerIDArr.length + " Customer"}
                {/* <span className="account-inner-icon">
                  {TotalCustomerReceiveAmt - TotalCustomerGivenAmt > 0
                    ? "You Give"
                    : "You Get"}
                </span> */}
              </h4>
            </ListItem>
          </List>
          <List
            sx={{
              py: 0,
              margin: "12px 10px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              color: "#3e3e3e !important",
            }}
          >
            <ListItem
              sx={{ display: "grid" }}
              button
              component={Link}
              to={"/account-statement/supplier"}
            >
              <h4 className="account-inners">
                <LocalShippingOutlinedIcon
                  sx={{ verticalAlign: "middle", mr: 2, color: "#1976d2" }}
                />
                Net Balance
                <KeyboardArrowRightIcon
                  sx={{
                    verticalAlign: "bottom",
                    right: "15px",
                    position: "absolute",
                  }}
                />
              </h4>
              <h3 className="account-inners-main">
                Supplier Ledger
                <span
                  className="account-inner-icon"
                  style={{
                    color:
                      TotalSupplierReceiveAmt - TotalSupplierGivenAmt > 0
                        ? "#008000"
                        : "#ff0000",
                  }}
                  // style={{
                  //   color:
                  //     TotalSupplierReceiveAmt - TotalSupplierGivenAmt > 0
                  //       ? "#ff0000"
                  //       : "#008000",
                  // }}
                >
                  {"AED " +
                    (TotalSupplierReceiveAmt - TotalSupplierGivenAmt)
                      .toString()
                      .replace("-", "")}
                </span>
              </h3>
              <h4 className="account-inners">
                <PersonOutlineIcon sx={{ verticalAlign: "sub", mr: 1 }} />
                {SupplierIDArr.length + " Supplier"}
                {/* <span className="account-inner-icon">
                  {TotalSupplierReceiveAmt - TotalSupplierGivenAmt > 0
                    ? "You Get"
                    : "You Give"}
                </span> */}
              </h4>
            </ListItem>
          </List>
        </>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              p: 0.8,
              margin: "12px 10px",
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={90}
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={50}
                height={20}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={120}
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={100}
                height={20}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={100}
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={70}
                height={20}
              />
            </Box>
          </Box>
          <Box
            sx={{
              p: 0.8,
              margin: "12px 10px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              mt: 1,
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={90}
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={50}
                height={20}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={120}
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={100}
                height={20}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={100}
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={70}
                height={20}
              />
            </Box>
          </Box>
        </React.Fragment>
      )}
      {!CustomerOverview.isLoading ? (
        <Box sx={{ mt: 3, display: "block" }}>
          <Heading
            heading="Customer Ledger Pie Chart"
            css={{
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
              color: "#344767",
            }}
          />
          <Box sx={{ pl: 3, pt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: ".7rem",
                  height: ".7rem",
                  bgcolor: "#36c",
                  borderRadius: "100%",
                }}
              ></Box>
              <Description
                description={"Customer Payment"}
                css={{ ml: 1, fontSize: "14px", fontWeight: "500" }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: ".7rem",
                  height: ".7rem",
                  bgcolor: "#dc3912",
                  borderRadius: "100%",
                }}
              ></Box>
              <Description
                description={"Supplier Payment"}
                css={{ ml: 1, fontSize: "14px", fontWeight: "500" }}
              />
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: ".7rem", height: ".7rem", bgcolor: "#ccc", borderRadius: "100%" }}></Box>
              <Description description={"Customer Net Balance"} css={{ ml: 1, fontSize: "14px", fontWeight: "500" }} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: ".7rem", height: ".7rem", bgcolor: "#109618", borderRadius: "100%" }}></Box>
              <Description description={"Supplier Net Balance"} css={{ ml: 1, fontSize: "14px", fontWeight: "500" }} />
            </Box> */}
          </Box>
          <Chart
            chartType="PieChart"
            data={data}
            options={options}
            className="pie-chart"
            width="100%"
            height="300px"
          />
          <br />
          <br />
        </Box>
      ) : (
        <Box sx={{ my: 1, mx: 2 }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={250}
            height={20}
            sx={{ margin: "auto", my: 2 }}
          />
          <Box sx={{ display: "inline-block" }}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={200}
              height={20}
              sx={{ my: 1 }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={200}
              height={20}
              sx={{ my: 1 }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={200}
              height={20}
              sx={{ my: 1 }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={200}
              height={20}
              sx={{ my: 1 }}
            />
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "space-around" }}
            className="chart-skeleton"
          >
            <Skeleton
              animation="wave"
              variant="circular"
              width={200}
              height={200}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Dashboard;
