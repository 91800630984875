import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { getLoginCheck } from "./../../Auth/Authentication";
import Logo from "./../../images/par-logo.png";
import { useNavigate } from "react-router-dom";
import CertifiedImg from "./../../images/certified.png";
import SecureImg from "./../../images/secure.png";
import PlaystoreImg from "./../../images/playstore.png";
import { LoginUser } from "./ApiCalls";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Description } from "../../Components/Common";

function Copyright(props) {
  return (
    <>
      <Typography
        className="description"
        align="center"
        {...props}
        sx={{ fontSize: "14px", color: "#333" }}
      >
        {"Do not have an account "}
        <Link
          color="inherit"
          href="https://api.whatsapp.com/send?phone=+919399171727&amp;text=Hello%20I%20Want%20To%20Register%20In%20PAR%20App%20.%20Please%20Help%20Me%20With%20The%20Details.%20Thanks!#"
          className="description"
        >
          sign up
        </Link>
      </Typography>
      {/* <Description
        description="Privacy policy | Terms & conditions"
        css={{ fontSize: "14px", color: "#333" }}
      /> */}
      <Typography
        className="description"
        align="center"
        {...props}
        sx={{ fontSize: "14px", color: "#333" }}
      >
        <Link color="inherit" href="/privacy-policy" className="description">
          Privacy policy | Terms & conditions
        </Link>
      </Typography>
      <Typography
        className="description"
        align="center"
        {...props}
        sx={{ fontSize: "14px", color: "#333" }}
      >
        {"Copyright © "}
        <Link
          color="inherit"
          href="https://parwebsite.rabs.support/"
          className="description"
        >
          Par App
        </Link>
        {" " + new Date().getFullYear()}
      </Typography>
    </>
  );
}

const defaultTheme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [Error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [BtnStatus, setBtnStatus] = useState(false);
  const [showComponent, setShowComponent] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    getLoginCheck()
      .then((response) => {
        if (response.data !== "Session found") {
          navigate("/login");
        } else {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      setShowComponent(true);
    }, 10000);
  }, []);

  const onSubmit = (event) => {
    setBtnStatus(true);
    event.preventDefault();
    var data = new FormData(event.currentTarget);
    LoginUser(data)
      .then((response) => {
        if (response.data.status === "Successfully Login Done") {
          navigate("/dashboard");
        } else {
          setBtnStatus(false);
          setError(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {showComponent ? (
        <Box
          sx={{
            height: "100vh",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={Logo} alt="Par App Logo" width={200} loading="lazy" />
            {/* <Typography component="h1" variant="h5" sx={{ fontWeight: " 500", textTransform: "uppercase" }}>
              Sign in
            </Typography> */}
            {Error !== "" ? (
              <Alert severity="error" sx={{ my: 1 }}>
                {Error}
              </Alert>
            ) : null}
            <Box
              component="form"
              noValidate
              onSubmit={onSubmit}
              sx={{ mt: 1, textAlign: "center" }}
            >
              <TextField
                margin="normal"
                required={true}
                fullWidth
                id="email"
                label="Username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoFocus
              />
              <FormControl sx={{ width: "100%", my: 1 }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  name="password"
                  label="Password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ my: 2 }}
                disabled={BtnStatus}
              >
                Sign In
              </Button>
              <Copyright sx={{ my: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "3rem",
                  paddingTop: ".25rem",
                }}
              >
                <img
                  src={CertifiedImg}
                  alt="Certified"
                  width="70px"
                  loading="lazy"
                />
                <img
                  src={PlaystoreImg}
                  alt="play store"
                  width="70px"
                  loading="lazy"
                />
                <img src={SecureImg} alt="Secure" width="90px" loading="lazy" />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="center-body">
          <Box className="">
            <img src={Logo} alt="Par App Logo" width={200} loading="lazy" />
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}
