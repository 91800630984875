import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ArticleIcon from "@mui/icons-material/Article";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EmailIcon from "@mui/icons-material/Email";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import {
  UpdateProfilePicture,
  getUserDetails,
  UpdateBusinessName,
  UpdateAddress,
  UpdateEmail,
  UpdateDescription,
  UpdatePersonName,
  UpdateBusinessType,
  UpdateTrnNumber,
  UpdateTlNumber,
} from "./../../Components/Setting/ApiCalls";
import { SubHeader } from "../../Components/SubHeader/SubHeader";
import "./Profile.css";
import { getLoginCheck } from "./../../Auth/Authentication";
import { useNavigate } from "react-router-dom";
import { API_URL } from "./../../App";
import BottomDrawer from "../../Helper/BottomDrawer/BottomDrawer";
import BusinessCardDrawer from "../../Components/BusinessCardDrawer/BusinessCardDrawer";

const Profile = () => {

  const navigate = useNavigate();

  const [showUploadBtn, setshowUploadBtn] = useState(false);
  const [BottomDraweropen, setBottomDraweropen] = useState(false);
  const [BusinessCardDraweropen, setBusinessCardDraweropen] = useState(false);
  const [UserDetails, setUserDetails] = useState({
    u_id: "",
    create_dt: "",
    update_dt: "",
    u_ccode: "",
    u_mob_no: "",
    business_name: "",
    address: "",
    business_type: "",
    email: "",
    description: "",
    person_name: "",
    profile_img: "",
    tl_no: "",
  });

  const [FormDialogData, setFormDialogData] = useState({
    Label: "",
    value: "",
    name: "",
    FormSubmitFunction: "",
  });

  const toggleDrawer = (newOpen) => () => {
    setBottomDraweropen(newOpen);
  };

  useEffect(() => {
    getLoginCheck()
      .then(response => {
        if (response.data !== 'Session found') {
          navigate('/login');
        }
      }).catch(err => {
        console.log(err);
      });
  }, [navigate]);

  const GetUserData = () => {
    getUserDetails()
      .then((response) => {
        setUserDetails(() => {
          return { ...response.data[0] };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetUserData();
  }, []);

  const ChangeProfilePicture = (event) => {
    event.preventDefault();
    var data = new FormData(event.currentTarget);
    UpdateProfilePicture(data)
      .then((response) => {
        GetUserData();
        setshowUploadBtn(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateUserProfile = (event) => {
    event.preventDefault();
    if (FormDialogData.Label === "Business Name") {
      const BusinessName = FormDialogData.value;
      UpdateBusinessName(BusinessName)
        .then((response) => {
          GetUserData();
          setBottomDraweropen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (FormDialogData.Label === "Address") {
      const Address = FormDialogData.value;
      UpdateAddress(Address)
        .then((response) => {
          GetUserData();
          setBottomDraweropen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (FormDialogData.Label === "Email") {
      const Email = FormDialogData.value;
      UpdateEmail(Email)
        .then((response) => {
          GetUserData();
          setBottomDraweropen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (FormDialogData.Label === "Description") {
      const Description = FormDialogData.value;
      UpdateDescription(Description)
        .then((response) => {
          GetUserData();
          setBottomDraweropen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (FormDialogData.Label === "Person Name") {
      const PersonName = FormDialogData.value;
      UpdatePersonName(PersonName)
        .then((response) => {
          GetUserData();
          setBottomDraweropen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (FormDialogData.Label === "Business Type") {
      const BusinessType = FormDialogData.value;
      UpdateBusinessType(BusinessType)
        .then((response) => {
          GetUserData();
          setBottomDraweropen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (FormDialogData.Label === "TRN Number") {
      const TrnNumber = FormDialogData.value;
      UpdateTrnNumber(TrnNumber)
        .then((response) => {
          GetUserData();
          setBottomDraweropen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (FormDialogData.Label === "TL Number") {
      const TlNumber = FormDialogData.value;
      UpdateTlNumber(TlNumber)
        .then((response) => {
          GetUserData();
          setBottomDraweropen(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChange = (e) => {
    const Test = { Label: FormDialogData.Label, value: e.target.value };
    setFormDialogData(() => {
      return { ...Test };
    });
  };

  const HandeleBusinessNameDialog = () => {
    const BusinessNameData = {
      Label: "Business Name",
      value: UserDetails.business_name,
      name: "business_name",
    };
    setFormDialogData(() => {
      return { ...BusinessNameData };
    });
    setBottomDraweropen(true);
  };

  const HandeleAddressDialog = () => {
    const AddressData = {
      Label: "Address",
      value: UserDetails.address,
      name: "address",
    };
    setFormDialogData(() => {
      return { ...AddressData };
    });
    setBottomDraweropen(true);
  };

  const HandeleEmailDialog = () => {
    const EmailData = {
      Label: "Email",
      value: UserDetails.email,
      name: "email",
    };
    setFormDialogData(() => {
      return { ...EmailData };
    });
    setBottomDraweropen(true);
  };
  const HandeleDescriptionDialog = () => {
    const DescriptionData = {
      Label: "Description",
      value: UserDetails.description,
      name: "description",
    };
    setFormDialogData(() => {
      return { ...DescriptionData };
    });
    setBottomDraweropen(true);
  };
  const HandelePersonNameDialog = () => {
    const PersonNameData = {
      Label: "Person Name",
      value: UserDetails.person_name,
      name: "person_name",
    };
    setFormDialogData(() => {
      return { ...PersonNameData };
    });
    setBottomDraweropen(true);
  };
  const HandeleTrnNumbaerDialog = () => {
    const TrnNumbaerData = {
      Label: "TRN Number",
      value: UserDetails.trn_no,
      name: "trn_no",
    };
    setFormDialogData(() => {
      return { ...TrnNumbaerData };
    });
    setBottomDraweropen(true);
  };
  const HandeleTlNumbaerDialog = () => {
    const TlNumbaerData = {
      Label: "TL Number",
      value: UserDetails.tl_no,
      name: "tl_no",
    };
    setFormDialogData(() => {
      return { ...TlNumbaerData };
    });
    setBottomDraweropen(true);
  };
  const HandeleBusinessTypeDialog = () => {
    const BusinessTypeData = {
      Label: "Business Type",
      value: UserDetails.business_type,
      name: "business_type",
    };
    setFormDialogData(() => {
      return { ...BusinessTypeData };
    });
    setBottomDraweropen(true);
  };

  const ProfileData = [

    {
      Title: "Business Name",
      Icon: (
        <AccountCircleOutlinedIcon
          sx={{ color: "#1976d2", fontSize: "2rem" }}
        />
      ),
      Description:
        UserDetails.business_name === "" ? null : UserDetails.business_name,
      onClickFun: HandeleBusinessNameDialog,
    },
    {
      Title: "Mobile Number",
      Icon: (
        <PhoneAndroidOutlinedIcon sx={{ color: "#1976d2", fontSize: "2rem" }} />
      ),
      Description: UserDetails.u_ccode + " " + UserDetails.u_mob_no,
      onClickFun: null,
    },
    {
      Title: "Business Type",
      Icon: (
        <BusinessOutlinedIcon sx={{ color: "#1976d2", fontSize: "2rem" }} />
      ),
      Description:
        UserDetails.business_type === ""
          ? "Select your business type"
          : UserDetails.business_type,
      onClickFun: HandeleBusinessTypeDialog,
    },
    {
      Title: "Address",
      Icon: (
        <LocationOnOutlinedIcon sx={{ color: "#1976d2", fontSize: "2rem" }} />
      ),
      Description:
        UserDetails.address === ""
          ? "Set Your Current Location "
          : UserDetails.address,
      onClickFun: HandeleAddressDialog,
    },
    {
      Title: "Email ID",
      Icon: <EmailIcon sx={{ color: "#1976d2", fontSize: "2rem" }} />,
      Description:
        UserDetails.email === "" ? "Enter Your Email ID" : UserDetails.email,
      onClickFun: HandeleEmailDialog,
    },
    {
      Title: "About Us",
      Icon: <DescriptionIcon sx={{ color: "#1976d2", fontSize: "2rem" }} />,
      Description:
        UserDetails.description === ""
          ? "Enter Your Description"
          : UserDetails.description,
      onClickFun: HandeleDescriptionDialog,
    },
    {
      Title: "Contact Person Name",
      Icon: <PersonIcon sx={{ color: "#1976d2", fontSize: "2rem" }} />,
      Description:
        UserDetails.person_name === ""
          ? "Enter Your Person Name"
          : UserDetails.person_name,
      onClickFun: HandelePersonNameDialog,
    },
    {
      Title: "TRN Number",
      Icon: <PersonIcon sx={{ color: "#1976d2", fontSize: "2rem" }} />,
      Description:
        UserDetails.trn_no === ""
          ? "Enter Your TRN Number"
          : UserDetails.trn_no,
      onClickFun: HandeleTrnNumbaerDialog,
    },
    {
      Title: "TL Number",
      Icon: <PersonIcon sx={{ color: "#1976d2", fontSize: "2rem" }} />,
      Description:
        UserDetails.tl_no === ""
          ? "Enter Your TL Number"
          : UserDetails.tl_no,
      onClickFun: HandeleTlNumbaerDialog,
    },
  ];

  return (
    <React.Fragment>
      <SubHeader PageHeading="Profile" backto="/setting" />
      <Box
        sx={{ margin: "6rem auto 2rem 6rem" }}
        component="form"
        onSubmit={ChangeProfilePicture}
      >
        <Badge
          overlap="circular"
          color="primary"
          className="photo-icon"
          component="label"
          aria-label="upload picture"
          badgeContent={
            <React.Fragment>
              <input
                hidden
                accept="image/*"
                type="file"
                name="user_profile"
                onChange={() => setshowUploadBtn(true)}
              />
              <PhotoCamera />
            </React.Fragment>
          }
        >
          {UserDetails.profile_img === "" ? (
            <Avatar
              className="cus-avatar"
              sx={{ width: "150px", height: "150px" }}
            />
          ) : (
            <Avatar
              src={`${API_URL}/Upload/UserProfile/${UserDetails.profile_img}`}
              className="cus-avatar"
              sx={{ width: "150px", height: "150px" }}
            />
          )}{" "}
        </Badge>
        {showUploadBtn ? (
          <Button type="submit" variant="contained" sx={{ marginTop: "1rem" }}>
            Change Image
          </Button>
        ) : null}
      </Box>
      <List sx={{ width: "95%", bgcolor: "background.paper" }}>
        <ListItem alignItems="flex-start" onClick={() => setBusinessCardDraweropen(true)}>
          <ListItemAvatar sx={{ marginTop: "12px", minWidth: "50px" }}>
            <ArticleIcon sx={{ color: "#1976d2", fontSize: "2rem" }} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body1"
                className="profile-subtitle"
              >
                Business Card<br />
              </Typography>
            }
            secondary={
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="h6"
                className="profile-title">
                Shared your business card
              </Typography>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
        {ProfileData.map((row, id) => {
          return (
            <React.Fragment key={id}>
              <ListItem alignItems="flex-start" onClick={row.onClickFun}>
                <ListItemAvatar sx={{ marginTop: "12px", minWidth: "50px" }}>
                  {row.Icon}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body1"
                        className="profile-subtitle"
                        onClick={() => setBottomDraweropen(true)}
                        onKeyDown={() => setBottomDraweropen(false)}
                      >
                        {row.Title}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="h6"
                        className="profile-title"
                      >
                        {row.Description}
                        <br />
                      </Typography>
                      {row.Title === "Business Name"
                        ? "Profile name will be visible to your customers"
                        : null}
                    </React.Fragment>
                  }
                />
                {row.Title === "Business Type" ? (
                  <span>
                    <KeyboardArrowDownIcon sx={{ marginTop: "1rem" }} />
                  </span>
                ) : null}
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          );
        })}
      </List>
      <BottomDrawer
        open={BottomDraweropen}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        Label={FormDialogData.Label}
        value={FormDialogData.value}
        name={FormDialogData.name}
        onChange={handleChange}
        Tester={UpdateUserProfile}
      />
      <BusinessCardDrawer
        open={BusinessCardDraweropen}
        onOpen={() => setBusinessCardDraweropen(true)}
        onClose={() => setBusinessCardDraweropen(false)}
      />
    </React.Fragment>
  );
};


export default Profile;
