import React from 'react'
import FooterNavbar from "../../Components/FooterNavbar/FooterNavbar";
import SearchBar from "../../Components/SearchBar/SearchBar";
const FixedPages = () => {
  return (
    <>
    <FooterNavbar/>
    <SearchBar/>
    </>
  )
}

export default FixedPages