import React, { useState, useCallback, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { toPng } from "html-to-image";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Divider from "@mui/material/Divider";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Button from "@mui/material/Button";
import { ButtonSetting, Description, Heading } from "../../Components/Common";
import Stack from "@mui/material/Stack";
import { saveAs } from "file-saver";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useQuery } from "react-query";
import { deepPurple } from "@mui/material/colors";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Link, useParams, useNavigate } from "react-router-dom";
import { numberToWords } from "amount-to-words";
import {
  EditSecurityPinDrawer,
  DeleteCreditDrawer,
  DelSecurityPinDrawer,
} from "./../../Helper/RightDrawer/RightDrawer";
import {
  getUserDetails,
  getPaymentDetails,
  UpdatePaymentNoteData,
  CheckSecurityPin,
  UpdateAmount,
  setBillImage,
  DeletePaymentBill,
  getCashReceipt,
} from "./ApiCall";
import dayjs from "dayjs";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import BottomDrawer from "../../Helper/BottomDrawer/BottomDrawer";
import BillingDrawer from "../../Components/BillingDrawer/BillingDrawer";
import { API_URL } from "./../../App";
import PARLogo from "../../images/par-logo.png";
import "./PaymentDetail.css"


const PaymentDetail = () => {

  const ref = useRef(null);

  const PaymentID = useParams().payment_id;
  const CustomerName = useParams().customer_name;
  const [Error, setError] = useState("");
  const [BottomDraweropen, setBottomDraweropen] = useState(false);
  const [BillDraweropen, setBillDraweropen] = useState(false);
  const [SecurityPinDrawer, setSecurityPinDrawer] = useState(false);
  const [DeleteCreditDrawerOpen, setDeleteCreditDrawerOpen] = useState(false);
  const [DeleteCreditPinDrawerOpen, setDeleteCreditPinDrawerOpen] =
    useState(false);
  const [SecurityPin, setSecurityPin] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [BillDrawerType, setBillDrawerType] = useState("");

  const [open, setOpen] = React.useState(false);

  const [FormDialogData, setFormDialogData] = useState({
    Label: "",
    value: "",
  });

  const PaymentDetails = useQuery(
    "PaymentDetails",
    () => {
      return getPaymentDetails(PaymentID);
    },
    {}
  );

  const UserDetails = useQuery(
    "PaymentUserDetails",
    () => {
      return getUserDetails();
    },
    {}
  );

  if (!PaymentDetails.isLoading) {
    const AedToWords = numberToWords(PaymentDetails.data.data[0].amount);
  }

  const CheckPin = async (event) => {
    event.preventDefault();
    CheckSecurityPin(SecurityPin)
      .then((response) => {
        console.log(response);
        if (response.data === "Correct Security Pin") {
          setError("");
          setSecurityPinDrawer(false);
          setBottomDraweropen(true);
        } else {
          setError(response.data);
        }
      });
  };

  const BottomDrawerForm = async (event) => {
    event.preventDefault();
    if (FormDialogData.Label === "Edit Note") {
      const note = FormDialogData.value;
      const p_id = PaymentDetails.data.data[0].p_id;
      UpdatePaymentNoteData(note, p_id)
        .then((response) => {
          if (response.status === 200) {
            setBottomDraweropen(false);
            PaymentDetails.refetch();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (FormDialogData.Label === "Edit Amount") {
      const data = FormDialogData.value;
      const p_id = PaymentDetails.data.data[0].p_id;
      UpdateAmount(data, p_id)
        .then((response) => {
          if (response.status === 200) {
            setBottomDraweropen(false);
            PaymentDetails.refetch();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const HandeleNoteDetails = () => {
    setBottomDraweropen(true);
    const NoteData = {
      Label: "Edit Note",
      value: PaymentDetails.data.data[0].p_note,
    };
    setFormDialogData(() => {
      return { ...NoteData };
    });
  };

  const HandeleAmountDetails = () => {
    setSecurityPinDrawer(true);
    const NoteData = {
      Label: "Edit Amount",
      value: PaymentDetails.data.data[0].amount,
    };
    setFormDialogData(() => {
      return { ...NoteData };
    });
  };

  const handleChange = (e) => {
    const Formcomponents = {
      Label: FormDialogData.Label,
      value: e.target.value,
      FormSubmitFunction: FormDialogData.FormSubmitFunction,
    };
    setFormDialogData(() => {
      return { ...Formcomponents };
    });
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const HandleBillForm = (event) => {
    event.preventDefault();
    var data = new FormData(event.currentTarget);
    setBillImage(data)
      .then((response) => {
        if (response.status === 200) {
          setSelectedImage("")
          setBillDraweropen(false);
          PaymentDetails.refetch()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const DeleteBill = () => {
    const p_bill = PaymentDetails.data.data[0].p_bill;
    DeletePaymentBill(p_bill, PaymentID)
      .then((response) => {
        PaymentDetails.refetch()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const DownloadCashReceipt = useCallback(() => {
    setOpen(true)
    if (ref.current === null) {
      return;
    }
    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        getCashReceipt(dataUrl, PaymentID)
          .then((response) => {
            setOpen(false)
            saveAs(`${API_URL}/Images/cash-receipt/${response.data}`, "Cash-Reciept");
          })
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  const onButtonClick = useCallback(() => {
    setOpen(true)
    if (ref.current === null) {
      return;
    }
    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        getCashReceipt(dataUrl, PaymentID)
          .then((response) => {
            setOpen(false)
            console.log(response)
            console.log(`${API_URL}/Images/cash-receipt/${response.data}`)
            const link = document.createElement('a')
            link.setAttribute('href', 'whatsapp://send?text=' + encodeURIComponent(`${API_URL}/Images/cash-receipt/${response.data}`));
            link.setAttribute('data-action', 'share/whatsapp/share')
            link.click()
          })
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  // const handleClick = () => {
  //   navigate("https://par.rabs.support/download-recipt");
  // };

  return (
    <>
      <React.Fragment>
        {!PaymentDetails.isLoading ? (
          <>
            <AppBar sx={{ position: "relative" }} className="cus-chat-header">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  component={Link}
                  to={"/chatpages/" + PaymentDetails.data.data[0].customer_id}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Stack direction="row" spacing={1}>
                  <Avatar
                    alt={CustomerName}
                    src="/static/images/avatar/1.jpg"
                    sx={{ bgcolor: deepPurple[500] }}
                  />
                </Stack>
                <Heading
                  heading={CustomerName}
                  css={{ ml: 2, flex: 1, textTransform: "capitalize", fontSize: "20px", fontWeight: "600" }}
                />
                <IconButton color="inherit" component={Link} to="/setting">
                  <HelpOutlineOutlinedIcon />
                </IconButton>
              </Toolbar>
            </AppBar>

            <Box sx={{ bgcolor: "#e4e4e4", mt: 8 }}>

              <Box className="payment-detail-card" sx={{ p: 6, textAlign: "center" }}>
                <Heading heading={"AED " + PaymentDetails.data.data[0].amount}
                  css={{
                    fontSize: "34px", fontWeight: "600",
                    color: PaymentDetails.data.data[0].type === "given" ? "red" : "green",
                    position: "relative"
                  }} />
                <CreateIcon
                  sx={{
                    padding: "5px",
                    background: "#d8a24a36",
                    borderRadius: "50%",
                    color: "#d8a24a",
                    fontSize: "22px",
                    position: "absolute",
                    top: "5rem",
                    left: "auto",
                    right: "5rem",
                  }}
                  onClick={HandeleAmountDetails}
                />
              </Box>

              <Box className="payment-detail-card sub-card" sx={{ my: 1 }}>
                <Heading heading="Bills" />
                <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                  <Box className="add-bill-card" onClick={() => { setBillDrawerType("Add Bill"); setBillDraweropen(true) }}>
                    <AddAPhotoOutlinedIcon sx={{ color: "#d8a24a" }} />
                    <Heading heading="Add Bill" css={{ fontSize: "16px", color: "#d8a24a", mt: .5 }} />
                  </Box>
                  {PaymentDetails.data.data[0].p_bill !== "" ?
                    <Avatar
                      src={`${API_URL}/Upload/PaymentBill/${PaymentDetails.data.data[0].p_bill}`}
                      sx={{ width: 80, height: 80, borderRadius: "5px", ml: 2 }}
                      onClick={() => { setBillDrawerType("Preview"); setBillDraweropen(true) }}
                      className="bill-image"
                    />
                    : null}
                </Box>
              </Box>

              <Box className="payment-detail-card sub-card" sx={{ my: 1 }}>
                <Heading heading="Notes" />
                <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                  <NoteAddIcon sx={{ color: "#d8a24a", mr: 1, my: 0.5 }} />
                  {PaymentDetails.data.data[0].p_note === "" ? (
                    <TextField
                      id="input-with-sx"
                      label="Add Note"
                      variant="standard"
                      sx={{ width: "100%" }}
                      onClick={HandeleNoteDetails}
                      disabled
                    />
                  ) : (
                    <Heading
                      heading={PaymentDetails.data.data[0].p_note}
                      onClick={HandeleNoteDetails}
                      css={{ textTransform: "capitalize", fontSize: "18px" }} />
                  )}
                </Box>
              </Box>

              <Box className="payment-detail-card sub-card" sx={{ my: 1 }}>
                <ButtonSetting
                  primary={"Added On " + dayjs(PaymentDetails.data.data[0].created_dt).format("DD MMM YYYY, mm:hh A")}
                  icon={<DateRangeIcon sx={{ color: "#000" }} />}
                />
                <Divider
                  variant="inset"
                  component="span"
                  className="divider"
                />
                <ButtonSetting
                  primary={"Added On " + dayjs(PaymentDetails.data.data[0].created_dt).format("DD MMM YYYY, mm:hh A")}
                  icon={<DateRangeIcon sx={{ color: "#000" }} />}
                />
                <Divider
                  variant="inset"
                  component="span"
                  className="divider"
                />
                <Box onClick={HandeleAmountDetails}>
                  <ButtonSetting
                    primary="Edit Transaction"
                    icon={<CreateIcon sx={{ color: "#008000" }} />}
                  />
                </Box>
              </Box>
              <Box
                className="payment-detail-card delete-card"
                sx={{ mt: 1 }}
                onClick={() => setDeleteCreditDrawerOpen(true)}>
                <ButtonSetting
                  primary="Delete Transaction"
                  icon={<DeleteIcon sx={{ color: "#ff0000" }} />}
                />
              </Box>
            </Box>

            {/* <Box className="fotter-custom-main" sx={{ width: "100%" }}> */}
            <Box sx={{ my: 2, display: "flex", justifyContent: "space-evenly", alignItems: "center", textAlign: "center" }}>
              <Button
                sx={{
                  color: "#fff ",
                  backgroundColor: "#316b04 !important",
                  borderRadius: "22px",
                  width: "90%",
                  padding: "7px 30px",
                }}
                onClick={onButtonClick}
                variant="contained"
                startIcon={<WhatsAppIcon />}
              >
                Share
              </Button>
              {/* <Button
                sx={{
                  color: "#d8a24a",
                  borderColor: "#d8a24a",
                  borderRadius: "22px",
                  padding: "7px 30px",
                }}
                onClick={DownloadCashReceipt}
                variant="outlined"
                startIcon={<CloudDownloadIcon />}
              >
                Download
              </Button> */}
            </Box>
            {/* </Box> */}
            <EditSecurityPinDrawer
              PaymentId={PaymentDetails.data.data[0].p_id}
              Title="Enter Security Pin"
              Error={Error}
              open={SecurityPinDrawer}
              value={SecurityPin}
              onChange={setSecurityPin}
              submitform={CheckPin}
              CloseSecurityPinDrawer={() => {
                setSecurityPinDrawer(false);
              }}
            />
            <BottomDrawer
              open={BottomDraweropen}
              onOpen={() => setBottomDraweropen(true)}
              onClose={() => setBottomDraweropen(false)}
              Label={FormDialogData.Label}
              value={FormDialogData.value}
              onChange={handleChange}
              Tester={BottomDrawerForm}
            />
            <DelSecurityPinDrawer
              Title="Enter Security"
              CustomerID={PaymentDetails.data.data[0].customer_id}
              PaymentId={PaymentDetails.data.data[0].p_id}
              open={DeleteCreditPinDrawerOpen}
              CloseSecurityPinDrawer={() => {
                setDeleteCreditDrawerOpen(true);
                setDeleteCreditPinDrawerOpen(false);
              }}
            />
            <DeleteCreditDrawer
              Title="Delete Credit"
              Amount={PaymentDetails.data.data[0].amount}
              PaymentDate={PaymentDetails.data.data[0].payment_dt}
              PaymentId={PaymentDetails.data.data[0].p_id}
              open={DeleteCreditDrawerOpen}
              CloseDeleteCreditDrawer={() => setDeleteCreditDrawerOpen(false)}
              DeleteBtnClick={() => {
                setDeleteCreditDrawerOpen(false);
                setDeleteCreditPinDrawerOpen(true);
              }}
            />
            <BillingDrawer
              open={BillDraweropen}
              onOpen={() => setBillDraweropen(true)}
              onClose={() => { setBillDraweropen(false); setSelectedImage("") }}
              BillForm={HandleBillForm}
              p_id={PaymentID}
              p_bill={PaymentDetails.data.data[0].p_bill}
              Type={BillDrawerType}
              ImageStatus={selectedImage}
              DeleteBillFun={DeleteBill}
              BillData={
                <>
                  <Box sx={{ display: "inline-block" }}>
                    {selectedImage !== "" ?
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Bill Preview"
                        width="100%"
                        height="auto"
                      />
                      : null}
                    <Box sx={{ margin: "auto", bgcolor: "#58a650", borderRadius: "30px", opacity: selectedImage === "" ? "1" : "0" }}>
                      <IconButton
                        sx={{
                          display: "flex",
                          justifyContent: " center",
                          alignItems: "center"
                        }}
                        aria-label="delete"
                        size="small">
                        <AddPhotoAlternateIcon fontSize="inherit" sx={{ color: "#fff", fontSize: "2rem", p: .5 }} />
                        <input
                          type="file"
                          name="p_bill"
                          className="bill-input-filed"
                          onChange={imageChange}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  {selectedImage === "" ?
                    <Heading heading="Gallery" css={{ fontWeight: "600", color: "#000" }} />
                    : null}
                </>
              }
            />
          </>
        ) : (
          <React.Fragment>
            <Box className="skeleton-chat-header">
              <Skeleton
                animation="wave"
                variant="circular"
                width={100}
                height={40}
                sx={{ mx: 1 }}
              />
              <Skeleton
                animation="wave"
                height={30}
                width="100%"
                sx={{ mx: 1 }}
              />
              <Skeleton
                animation="wave"
                variant="circular"
                width={100}
                height={40}
                sx={{ mx: 1 }}
              />
              <Skeleton
                animation="wave"
                variant="circular"
                width={100}
                height={40}
                sx={{ mx: 1 }}
              />
              <Skeleton
                animation="wave"
                variant="circular"
                width={100}
                height={40}
                sx={{ mx: 1 }}
              />
            </Box>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="90%"
              height={150}
              sx={{ margin: "auto", mt: 3 }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="90%"
              height={70}
              sx={{ margin: "auto", mt: 3 }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="90%"
              height={70}
              sx={{ margin: "auto", mt: 3 }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="90%"
              height={70}
              sx={{ margin: "auto", mt: 3 }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="90%"
              height={70}
              sx={{ margin: "auto", mt: 3 }}
            />
          </React.Fragment>
        )}
        {!PaymentDetails.isLoading && !UserDetails.isLoading ? (
          <div
            style={{
              position: "absolute",
              left: "-10000px",
              top: 0,
            }}
          >
            <Box ref={ref} style={{ backgroundColor: "#fff", width: "500px", py: 1 }}>
              <img src={PARLogo} alt="Par App Logo" width={100} />
              <Box sx={{ background: "#eab45e", py: 3, position: "relative" }}>
                <Typography sx={{
                  right: 0,
                  background: "#fff",
                  position: "absolute",
                  fontSize: "36px",
                  mr: 6,
                  my: -3.4,
                  py: 0,
                  px: 2,
                }}>
                  INVOICE
                </Typography>
              </Box>
              <Box sx={{ px: 2.5, my: 2 }}>
                <Typography sx={{ m: 0, pb: 0.5 }}>
                  Date :{" "}
                  {dayjs(PaymentDetails.data.data[0].created_dt).format(
                    "DD/MM/YYYY"
                  )}
                </Typography>
                <Typography sx={{ m: 0, pb: 0.5 }}>
                  Address: {UserDetails.data.data[0].address}
                </Typography>
                <Typography sx={{ m: 0, pb: 0.5 }}>
                  Cash Receipt : {PaymentDetails.data.data[0].p_id}
                </Typography>
                <Typography sx={{ m: 0, pb: 0.5 }}>
                  PO Box. : {UserDetails.data.data[0].u_mob_no}
                </Typography>
                <Typography sx={{ m: 0, pb: 0.5 }}>
                  TRN No. : {UserDetails.data.data[0].trn_no}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: 2,
                  pt: 0,
                  mx: 3,
                  pb: 1,
                  borderTop: "2px solid #8b8b8b",
                  borderBottom: "2px solid #8b8b8b",
                }}
              >
                <Box
                  sx={{
                    my: 2,
                    pt: 2,
                    pb: 0,
                    borderTop: "2px solid #8b8b8b",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                  <Heading
                    css={{
                      borderRight: "1px solid #8b8b8b",
                      p: 2,
                      fontSize: "600",
                    }}
                    heading="Holder Name"
                  />
                  <Heading
                    css={{
                      p: 2,
                      width: "20%",
                      borderRight: "1px solid #8b8b8b",
                      fontSize: "600",
                    }}
                    heading={
                      PaymentDetails.data.data[0].status === "received"
                        ? CustomerName
                        : UserDetails.data.data[0].username
                    }
                  />
                  <Heading
                    css={{
                      background: "#eab45e",
                      p: 2,
                      textAlign: "center",
                      ml: 4,
                      color: "#fff",
                    }}
                    heading={"Amount AED " + PaymentDetails.data.data[0].amount}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  mx: 3,
                  py: 1,
                  borderTop: "2px solid #8b8b8b",
                  borderBottom: "2px solid #8b8b8b",
                }}
              >
                <Typography sx={{ m: 0 }}>
                  Cash{" "}
                  {PaymentDetails.data.data[0].status === "received"
                    ? " Received From "
                    : " Given To "}{" "}
                  {PaymentDetails.data.data[0].status === "received"
                    ? CustomerName
                    : UserDetails.data.data[0].username}{" "}
                  {" Of AED " + PaymentDetails.data.data[0].amount}
                </Typography>

                <Typography sx={{ m: 0 }}>
                  Amount in words :{" "}
                  {numberToWords(PaymentDetails.data.data[0].amount)} Only
                </Typography>
              </Box>
              <Typography sx={{ my: 1, px: 2.5 }}>
                Description : {PaymentDetails.data.data[0].p_note}
              </Typography>
              <Box
                sx={{
                  py: 1,
                  mx: 1,
                  borderTop: "3px solid #eab45e",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{ borderRight: "1px solid #8b8b8b" }}
                  className="cash-receipt-printview-header"
                  variant="body1"
                >
                  Phone: <br /> {UserDetails.data.data[0].u_mob_no}
                </Typography>
                <Typography
                  sx={{ borderRight: "1px solid #8b8b8b" }}
                  className="cash-receipt-printview-header"
                  variant="body1"
                >
                  Email: <br /> {UserDetails.data.data[0].email}
                </Typography>
                <Typography
                  className="cash-receipt-printview-header "
                  variant="body1"
                >
                  Website: <br /> {UserDetails.data.data[0].u_mob_no}
                </Typography>
              </Box>
              <Typography
                sx={{ pb: 0, mb: 2, textAlign: "center" }}
                variant="body1"
                gutterBottom
              >
                Document Generated throught PAR. No signature required.
              </Typography>
            </Box>
          </div>
        ) : null}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment >
    </>
  );
};

export default PaymentDetail;
