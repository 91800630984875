import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import Person3Icon from '@mui/icons-material/Person3';
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import "./FooterNavbar.css";

export default function FooterNavbar() {
  const [value, setValue] = useState("dashboard");

  useEffect(() => {
    if (window.location.pathname === '/dashboard') {
      setValue('dashboard');
    } else if (window.location.pathname === '/customer') {
      setValue('customer');
    } else if (window.location.pathname === '/supplier') {
      setValue('supplier');
    } else if (window.location.pathname === '/setting') {
      setValue('setting');
    }
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className="cus-bottomnavigation">
        <BottomNavigation
          sx={{ mx: 2, padding: "0.6rem 0.6rem 0.3rem" }}
          value={value}
          onChange={handleChange}
        >
          <BottomNavigationAction
            component={Link}
            to="/dashboard"
            label="Dashboard"
            value="dashboard"
            icon={<HomeOutlinedIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/customer"
            label="Customer"
            value="customer"
            icon={<Person3Icon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/supplier"
            label="Supplier"
            value="supplier"
            icon={<StorefrontOutlinedIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/setting"
            label="Settings"
            value="setting"
            icon={<SettingsIcon />}
          />
        </BottomNavigation>
      </Box>
      <Outlet />
    </>
  );
}
