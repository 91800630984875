import Axios from "axios";
import { API_URL } from "../../App";

export const getcustomerDetails = async () => {
    try {
        const response = await Axios.post(`${API_URL}/account-statement/get-all-customer`);
        return response;
    } catch (err) {
        return err;
    }
};

export const getUserDetails = async (UserID) => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/get-user-details`, UserID);
        return response;
    } catch (err) {
        return err;
    }
}

export const getPayment = async (Type) => {
    try {
        const response = await Axios.post(`${API_URL}/account-statement/get-all-payment`, {
            type: Type.type
        });
        return response;
    } catch (err) {
        return err;
    }
}

export const getAccountStatement = async (dataUrl) => {
    try {
        const response = await Axios.post(`${API_URL}/payment/send-account-statement`, {
            account_statement_data: dataUrl
        });
        return response;
    } catch (err) {
        return err;
    }
};