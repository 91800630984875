import React from "react";
import Button from "@mui/material/Button";
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import { Heading } from "../Common";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import PaymentSuccesfull from "./../../images/payment-succesfull.gif"
import "./PaymentDrawer.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PaymentDrawer = (props) => {

  return (
    <>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose}
        TransitionComponent={Transition}
      >
        <Box sx={{ display: props.Status ? "none" : "block" }}>
          <CloseOutlinedIcon sx={{ fontSize: "1.6rem", m: 2, fontWeight: "600" }} onClick={props.onClick} />
          <Box component="form" onSubmit={props.onSubmit}>
            <input type="hidden" readOnly className="custom-field-design" name="CustomerNo" value={props.CustomerNo} placeholder={props.CustomerName} />
            <input type="hidden" readOnly className="custom-field-design" name="CustomerId" value={props.CustomerId} placeholder="{props.CustomerId}" />
            <input type="hidden" readOnly className="custom-field-design" name="Payment_type" value={props.CustomerType} placeholder="customer" />
            <input type="hidden" readOnly className="custom-field-design" name="Payment_status" value={props.given} placeholder={props.given} />
            <input type="hidden" readOnly className="custom-field-design" name="Person_name" value={props.CustomerName} placeholder="{props.CustomerName}" />
            <Box sx={{ mt: 3, textAlign: "center" }} className="payment-container">
              <Avatar
                sx={{ bgcolor: deepPurple[500], margin: "auto" }}
                alt={props.CustomerName}
                src="/static/images/avatar/1.jpg"
              />
              <Heading css={{ mt: 2, fontSize: "1.15rem", fontWeight: "600" }} heading={"Paying " + props.CustomerName} />
              <Heading heading={"+ " + props.CustomerNo} />
              <Box sx={{}} className="payment-input-container">
                <Heading heading={"AED"} css={{ fontSize: "1.3rem", color: "#000", mr: 1 }} />
                <input name="Payment_amount" autoComplete="off" type="number" placeholder="0" autoFocus required />
              </Box>
              <TextareaAutosize name="Payment_note" placeholder="Add a Note" className="note-textarea" required />
            </Box>
            <Box sx={{ position: "absolute", bottom: "2rem", right: "2rem", textAlign: "right" }}>
              <Button variant="contained" type="submit" disabled={props.BtnStatus}><EastOutlinedIcon /></Button>
            </Box>
          </Box>
        </Box>
        <img src={PaymentSuccesfull} alt="Wait to complete payment" style={{ margin: "auto", display: props.Status ? "block" : "none" }} />
      </Dialog>
    </>
  )
}

export default PaymentDrawer