import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import { useQuery } from "react-query";
import { API_URL } from "../../App";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { getUserDetails } from "../../Auth/Authentication";
import Skeleton from '@mui/material/Skeleton';
import "./SearchBar.css";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function SearchBar() {

  const UserDetails = useQuery("CPUserDetails", () => {
    return getUserDetails()
  }, {})

  return (
    <AppBar
      className="custom-header-search"
      position="static"
      color="transparent"
    >
      <Toolbar className="custom-toolbar">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
        ></Typography>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <Box component={Link}
            to="/search" sx={{ color: "#000" }}>
            <StyledInputBase
              sx={{ fontSize: "14px" }}
              placeholder="Search by name ...."
              inputProps={{ "aria-label": "search" }}
            />
          </Box>
        </Search>
        <ListItemAvatar sx={{ minWidth: "46px" }}>
          {!UserDetails.isLoading ?
            <Avatar
              component={Link}
              to="/profile"
              alt={UserDetails.data.data[0].person_name}
              src={`${API_URL}/Upload/UserProfile/${UserDetails.data.data[0].profile_img}`}
              sx={{ width: "35px", height: "35px", textDecoration: "none", boxShadow: "0px 0px 10px #ccc" }}
            />
            :
            <Skeleton animation="wave" variant="circular" width={40} height={40} />
          }
        </ListItemAvatar>
      </Toolbar>
    </AppBar>
  );
}
