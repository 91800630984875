import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ForwardIcon from "@mui/icons-material/Forward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Box from "@mui/material/Box";
import { ToastContainer } from "react-toastify";
import "./ChatPageFooter.css";
import PaymentDrawer from "../PaymentDrawer/PaymentDrawer";

export default function ChatPageFooter(props) {
  return (
    <>
      <ToastContainer />
      <Box className="fotter-custom-main" sx={{ width: "100%" }}>
        <Stack direction="row" className="fotter-custom">
          <Button
            sx={{
              color: "green !important",
              borderColor: "green !important",
            }}
            // sx={{
            //     color:
            //         props.CustomerType === "supplier"
            //             ? "red !important"
            //             : "green !important",
            //     borderColor:
            //         props.CustomerType === "supplier"
            //             ? "red !important"
            //             : "green !important",
            // }}
            variant="outlined"
            className="btn-custom"
            startIcon={<ForwardIcon sx={{ transform: "rotate(90deg)" }} />}
            onClick={props.OpenReceivedModal}
          >
            Received
          </Button>
          {props.CustomerType === "supplier" ? (
            <Button
              sx={{
                color: "red !important",
                borderColor: "red !important",
              }}
              variant="outlined"
              className="btn-custom"
              startIcon={<ForwardIcon sx={{ transform: "rotate(-90deg)" }} />}
              onClick={props.OpenGivenModal}
            >
              Payment
            </Button>
          ) : (
            <Button
            sx={{
              color: "red !important",
              borderColor: "red !important",
            }}
            // sx={{
            //   color:
            //     props.CustomerType === "supplier"
            //       ? "green !important"
            //       : "red !important",
            //   borderColor:
            //     props.CustomerType === "supplier"
            //       ? "green !important"
            //       : "red !important",
            // }}
            variant="outlined"
            className="btn-custom"
            startIcon={<ForwardIcon sx={{ transform: "rotate(-90deg)" }} />}
            onClick={props.OpenGivenModal}
          >
            Given
          </Button>
          )}
        </Stack>
      </Box>
      <PaymentDrawer
        open={props.GivenOpen}
        onClose={props.CloseGivenModal}
        onClick={props.CloseGivenModal}
        CustomerName={props.CustomerName}
        CustomerId={props.CustomerId}
        CustomerType={props.CustomerType}
        Status={props.PaymentStatus}
        CustomerNo={props.CustomerNo}
        given="given"
        onSubmit={props.onGivenSubmit}
        BtnStatus={props.BtnStatus}
      />
      <PaymentDrawer
        open={props.ReceivedOpen}
        onClose={props.CloseReceivedModal}
        Status={props.PaymentStatus}
        onClick={props.CloseReceivedModal}
        CustomerName={props.CustomerName}
        CustomerId={props.CustomerId}
        CustomerType={props.CustomerType}
        CustomerNo={props.CustomerNo}
        given="received"
        onSubmit={props.onReceivedSubmit}
        BtnStatus={props.BtnStatus}
      />
    </>
  );
}
