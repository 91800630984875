import Axios from 'axios';
import { API_URL } from './../../App';

export const LogoutUser = async (data) => {
    try {
        const response = await Axios.post(`${API_URL}/logout`);
        return response;
    } catch (err) {
        return err;
    }
}