import React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Webcam from "react-webcam";
import { API_URL } from "../../App";
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import "./BillingDrawer.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const drawerBleeding = 56;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const BillingDrawer = (props) => {
  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (props.Type === "Preview") {
    var Test = 100
  } else if (props.Type === "Add Bill" && props.ImageStatus === "") {
    Test = 30
  } else if (props.Type === "Capture Bill") {
    Test = 100
  } else {
    Test = 100
  }

  const webcamRef = React.useRef(null);
  const [url, setUrl] = React.useState(null);

  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
  }, [webcamRef]);

  const onUserMedia = (e) => {
    console.log(e);
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  return (
    <React.Fragment>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(${Test}% - ${drawerBleeding}px)`,
            overflow: "visible",
            marginTop: "10px",
          },
        }}
      />
      <Drawer
        container={container}
        anchor="bottom"
        open={props.open}
        // swipeAreaWidth="56"
        onClose={props.onClose}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Puller />
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
            mt: 2,
          }}
        >
          {/* <Box className="bill-preview-header">
            <Heading heading="Bill Image Preview" />
          </Box> */}
          {props.Type === "Add Bill" ?
            <Box component="form" onSubmit={props.BillForm} sx={{ width: "100%", my: 3, textAlign: "center" }}>
              <input hidden type="text" name="p_id" value={props.p_id} readOnly />
              {props.BillData}
              {props.ImageStatus && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 3,
                    width: "100%"
                  }}
                >
                  <Button className="add-image-btn" onClick={props.onClose}>
                    Cancel
                  </Button>
                  <Button className="add-image-btn" type="submit">
                    Add Image
                  </Button>
                </Box>
              )}
            </Box>
            : props.Type === "Preview" ?
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
                  <IconButton
                    aria-label="back"
                    sx={{ bgcolor: "#9d9d9d", color: "#fff" }}
                    onClick={props.onClose}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    sx={{ bgcolor: "#9d9d9d", color: "#fff" }}
                    onClick={props.DeleteBillFun}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Avatar
                  src={`${API_URL}/Upload/PaymentBill/${props.p_bill}`}
                  sx={{ width: "100%", height: "90%", borderRadius: "5px" }}
                  loading="lazy"
                />
              </Box>
              : props.Type === "Capture Bill" ?
                <Box sx={{ width: "100%", height: "50vh", overflow: "hidden" }}>
                  <Webcam
                    ref={webcamRef}
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    onUserMedia={onUserMedia}
                    style={{ height: "40vh" }}
                  />
                  <Box sx={{ textAlign: "center" }}>
                    <Button variant="outlined" onClick={capturePhoto}>Capture</Button>
                  </Box>
                  {url && (
                    <React.Fragment>
                      <img src={url} alt="Screenshot" sx={{ width: "100%" }}
                      />
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Button variant="outlined" onClick={() => setUrl(null)}>Retake</Button>
                        <Button variant="outlined" type="submit">Submit</Button>
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
                : null}
        </StyledBox>
      </Drawer>
    </React.Fragment >
  );
};

export default BillingDrawer;
