import Axios from "axios";
import { API_URL } from "../App";

export const getLoginCheck = async () => {
    try {
        const response = await Axios.post(`${API_URL}/check-login`);
        return response;
    } catch (err) {
        return err;
    }
}

export const getUserDetails = async () => {
    try {
        const response = await Axios.post(`${API_URL}/get-user-details`);
        return response;
    } catch (err) {
        return err;
    }
}

export const CheckSecurityPin = async (SecurityPin) => {
    try {
      const response = await Axios.post(`${API_URL}/check-security-pin`, {
        security_pin: SecurityPin
      });
      return response;
    } catch (err) {
      return err;
    }
  };
  