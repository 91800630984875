import Axios from "axios";
import { API_URL } from "../../App";

export const getPaymentDetails = async (PaymentID) => {
    try {
        const response = await Axios.post(`${API_URL}/payment/payment-detail`, {
            PaymentID: PaymentID,
        });
        return response;
    } catch (err) {
        return err;
    }
};

export const getUserDetails = async () => {
    try {
        const response = await Axios.post(`${API_URL}/payment/get-user-details`);
        return response;
    } catch (err) {
        return err;
    }
};