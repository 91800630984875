import Axios from "axios";
import { API_URL } from "../../App";

export const getCustomerPayment = async (CustomerID) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/get-customer-payment`, {
      CustomerID: CustomerID.customer_id,
    });
    return response;
  } catch (err) {

    return err;

  }
}
export const getCustomerDetails = async (CustomerID) => {
  try {
    const response = await Axios.post(`${API_URL}/customer/customer-detail`, {
      CustomerID: CustomerID.customer_id,
    });
    return response;
  } catch (err) {

    return err;

  }
}

export const getUserDetails = async () => {
  try {
    const response = await Axios.post(`${API_URL}/user-profile/get-user-details`);
    return response;
  } catch (err) {
    return err;
  }
}

export const getCustomerStatement = async (dataUrl, CustomerID) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/send-customer-statement`, {
      customer_statement_data: dataUrl,
      customer_id: CustomerID.customer_id,
    });
    return response;
  } catch (err) {
    return err;
  }
};
