import Axios from "axios";
import { API_URL } from "../../App";

export const getcustomerDetails = async (CustomerID) => {
  try {
    const response = await Axios.post(`${API_URL}/customer/customer-detail`, {
      CustomerID: CustomerID.id,
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getDeleteCustomer = async (CustomerID) => {
  try {
    const response = await Axios.post(`${API_URL}/customer/delete-customer`, {
      CustomerID: CustomerID.id,
    });
    return response;

  } catch (err) {

    return err;

  }
}

// ------------------------------------------ Payment Details Code Started Here -------------------------------- //
export const setPayment = async (data) => {
  // console.log(data)
  try {
    const response = await Axios.post(`${API_URL}/payment/add-payment`, {
      Payment_amount: data.get('Payment_amount'),
      Payment_note: data.get('Payment_note'),
      Person_name: data.get('Person_name'),
      CustomerNo: data.get('CustomerNo'),
      CustomerId: data.get('CustomerId'),
      Payment_type: data.get('Payment_type'),
      Payment_status: data.get('Payment_status')
    });
    return response;
  } catch (err) {
    return err;
  }
}

export const setPaymentReceived = async (data) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/add-received-payment`, {
      Payment_amount: data.get("Payment_amount"),
      Payment_note: data.get("Payment_note"),
      Person_name: data.get("Person_name"),
      CustomerNo: data.get("CustomerNo"),
      CustomerId: data.get("CustomerId"),
      Payment_type: data.get("Payment_type"),
      Payment_status: data.get("Payment_status"),
    });
    return response;
  } catch (err) {
    return err;
  }
}

export const UpdatePaymentNoteData = async (data) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/update-payment-note`, {
      p_note: data.get("p_note"),
      p_id: data.get("p_id"),
    });
    return response;
  } catch (err) {
    return err;
  }
}

export const UpdateAmount = async (data, payment_id) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/update-amount`, {
      amount: data.get("amount"),
      p_id: payment_id,
    });
    return response;
  } catch (err) {
    return err;
  }
}

export const getPayment = async (CustomerID) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/get-payment`, {
      CustomerID: CustomerID.id,
    });
    return response;
  } catch (err) {
    return err;

  }
}

export const CheckSecurityPin = async (SecurityPin) => {
  try {
    const response = await Axios.post(`${API_URL}/check-security-pin`, {
      security_pin: SecurityPin
    });
    return response;
  } catch (err) {
    return err;
  }
};