import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "react-query";
import { Heading } from "../Common";
import { ToWords } from "to-words";
import BlankPayment from "./../../images/no-payment.jpg";
import dayjs from "dayjs";
import "./InnerChatsMain.css";
import PaymentCard from "../PaymentCard/PaymentCard";

export default function InnerChatMain(props) {
  const [details, setDetails] = useState([]);

  const AllDateArr = [];

  props.PaymentGiven.map((row) => {
    if (AllDateArr.indexOf(row.payment_dt) === -1) {
      AllDateArr.push(row.payment_dt);
    }
  });

  return (
    <>
      <Box sx={{ mt: "5.5rem" }}>
        {AllDateArr.map((row, index) => {
          let dataCount = 0;
          return (
            <React.Fragment key={index}>
              {props.PaymentGiven.filter((data) => row === data.payment_dt).map(
                (data, id) => {
                  return (
                    <Box key={id}>
                      {dataCount === 0 ? (
                        <Stack
                          sx={{ pt: 0, mt: "auto", justifyContent: "center" }}
                          spacing={2}
                          direction="row"
                        >
                          <Button
                            disabled
                            sx={{
                              color: "#000 !important",
                              borderRadius: "28px !important",
                            }}
                            variant="contained"
                          >
                            {dayjs(row).format("D MMM YYYY")}
                          </Button>
                        </Stack>
                      ) : null}
                      <Box sx={{ display: "none" }}>{(dataCount = 1)}</Box>
                      <PaymentCard
                        CustomerType={props.CustomerType}
                        status={data.status}
                        amount={data.amount}
                        created_dt={data.created_dt}
                        bill={data.p_bill}
                        DetailsID={details.p_id}
                        p_id={data.p_id}
                        DelteStatus={data.delete}
                        p_note={data.p_note}
                        PaymentCardType={
                          props.CustomerType === "customer" &&
                          data.status === "given" &&
                          data.delete === "No"
                            ? "customer-sender"
                            : props.CustomerType === "customer" &&
                              data.status === "received" &&
                              data.delete === "No"
                            ? "customer-receiver"
                            : props.CustomerType === "supplier" &&
                              data.status === "received" &&
                              data.delete === "No"
                            ? "supplier-receiver"
                            : props.CustomerType === "supplier" &&
                              data.status === "given" &&
                              data.delete === "No"
                            ? "supplier-sender"
                            : props.CustomerType === "customer" &&
                              data.status === "given" &&
                              data.delete === "Yes"
                            ? "customer-sender-delete"
                            : props.CustomerType === "customer" &&
                              data.status === "received" &&
                              data.delete === "Yes"
                            ? "customer-receiver-delete"
                            : props.CustomerType === "supplier" &&
                              data.status === "received" &&
                              data.delete === "Yes"
                            ? "supplier-receiver-delete"
                            : props.CustomerType === "supplier" &&
                              data.status === "given" &&
                              data.delete === "Yes"
                            ? "supplier-sender-delete"
                            : null
                        }
                        // PaymentCardType={
                        //   props.CustomerType === "customer" && data.status === "given" && data.delete === "No"
                        //     ? "customer-sender"
                        //     : props.CustomerType === "customer" && data.status === "received" && data.delete === "No"
                        //       ? "customer-receiver"
                        //       : props.CustomerType === "supplier" && data.status === "received" && data.delete === "No"
                        //         ? "supplier-receiver"
                        //         : props.CustomerType === "supplier" && data.status === "given" && data.delete === "No"
                        //           ? "supplier-sender"
                        //           : props.CustomerType === "customer" && data.status === "given" && data.delete === "Yes"
                        //             ? "customer-sender-delete"
                        //             : props.CustomerType === "customer" && data.status === "received" && data.delete === "Yes"
                        //               ? "customer-receiver-delete"
                        //               : props.CustomerType === "supplier" && data.status === "received" && data.delete === "Yes"
                        //                 ? "supplier-receiver-delete"
                        //                 : props.CustomerType === "supplier" && data.status === "given" && data.delete === "Yes"
                        //                   ? "supplier-sender-delete"
                        //                   : null
                        // }
                        PaymentDetail={
                          "/payment-detail/" +
                          props.CustomerName +
                          "/" +
                          data.p_id
                        }
                      />
                    </Box>
                  );
                }
              )}
            </React.Fragment>
          );
        })}
      </Box>
      <br />
      {props.PaymentGiven.length !== 0 ? (
        <Box mb={11}>
          <List className="balance-datebox">
            <ListItem
              disableGutters
              sx={{
                color: props.DueAmt > 0 ? "#008000" : "#ff0000",
                fontWeight: 600,
              }}
              secondaryAction={
                props.DueAmt.toString().replace("-", "") + " AED"
              }
            >
              <ListItemText
                sx={{ color: "#000", fontWeight: 600 }}
                primary={props.DueAmt > 0 ? "Balance Advance" : "Balance Due"}
              />
            </ListItem>
          </List>
          {/* <BalanceDate
            TotalAmt={"AED " + props.DueAmt}
            AmtColorCode={props.DueAmt > 0 ? "#008000" : "#ff0000"}
            BalanceType={props.DueAmt > 0 ? "Balance Advance" : "Balance Due"}
          /> */}
        </Box>
      ) : (
        <Box style={{ textAlign: "center" }}>
          <img
            src={BlankPayment}
            alt="No Payment"
            width="70%"
            style={{ margin: "20px 0" }}
          />
          <Heading heading="All transactions between you and customer are totally private & secure" />
        </Box>
      )}
    </>
  );
}
