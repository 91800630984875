import React from 'react'
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import "./BottomDrawer.css"
import { Description, Heading } from '../../Components/Common';
import { Divider } from '@mui/material';

const drawerBleeding = 56;

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        // prefix="AED "
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const BottomDrawer = (props) => {

    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;

    const [values, setValues] = React.useState({
        textmask: '(100) 000-0000',
        numberformat: '1320',
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <React.Fragment>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(50% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                        marginTop: "10px"
                    },
                }}
            />
            <Drawer
                container={container}
                anchor="bottom"
                open={props.open}
                onClose={props.onClose}
                ModalProps={{
                    keepMounted: false,
                }}
            >
                <Puller />
                <StyledBox
                    sx={{
                        px: 2,
                        pb: 2,
                        height: '100%',
                        overflow: 'auto',
                        mt: 2
                    }}
                >
                    {props.Label === "Edit Amount" ?
                        <Box sx={{ mt: 1 }}>
                            <Heading heading={props.Label} css={{ fontWeight: "600" }} />
                            <Description description="Add the new amount here!" />
                            <Divider sx={{ my: 1 }} />
                        </Box>
                        : null}
                    <Box open={props.open} component="form" onSubmit={props.Tester} sx={{ mt: props.Label === "Business Type" ? 2 : "" }}>
                        {props.Label === "Business Type" ?
                            <Box sx={{ minWidth: 250 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{props.Label}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.value}
                                        label={props.Label}
                                        onChange={props.onChange}
                                    >
                                        <MenuItem value="Rentail Shop">Rentail Shop</MenuItem>
                                        <MenuItem value="Wholesale/Distributor">Wholesale/Distributor</MenuItem>
                                        <MenuItem value="Personal Use">Personal Use</MenuItem>
                                        <MenuItem value="Online Services">Online Services</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            :
                            <TextField
                                autoFocus
                                margin="dense"
                                value={props.value}
                                label={props.Label}
                                type="text"
                                name={props.name}
                                fullWidth
                                variant={props.Label === "Edit Amount" ? "outlined" : "standard"}
                                required
                                onChange={props.onChange}
                            />

                        }
                        <Box sx={{ mt: 2 }}>
                            <Button sx={{ mx: 1 }} variant="contained" onClick={props.onClose}>Cancel</Button>
                            <Button sx={{ mx: 1 }} variant="contained" type="submit">Update</Button>
                        </Box>
                    </Box>
                </StyledBox>
            </Drawer>
        </React.Fragment>
    )
}

export default BottomDrawer