import React from 'react'
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { deepOrange } from "@mui/material/colors";
import { Link } from "react-router-dom";

const UserList = (props) => {
    return (
        <>
            <ListItem
                button
                alignItems="flex-start"
                component={Link}
                to={props.redirect}
                label="ChatPages"
                value="chatpages"
                sx={{ pr: "4px" }}
            >
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: deepOrange[500], textTransform: "uppercase" }}>{props.name[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                className="chat-username"
                            >
                                {props.name}
                            </Typography>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                className="chat-username-info"
                            >
                                ✓ Added On {props.date}
                            </Typography>
                        </React.Fragment>
                    }
                />
                <ListItemText
                    className="chat-username-info"
                    sx={{
                        textAlign: "center",
                        color: props.Amount > 0 ? "#008000" : "#ff0000",
                        fontSize: ".830rem !important",
                    }}
                    primary={
                        <Typography
                            sx={{ color: props.Amount > 0 ? "#008000" : "#ff0000", fontWeight: "600" }}
                            variant="body2"
                        >
                            AED &nbsp;{props.Amount.toString().replace("-", "")}
                        </Typography>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{
                                    display: "inline",
                                    color: props.Amount > 0 ? "#008000" : "#ff0000",
                                    fontWeight: "600"
                                }}
                                component="span"
                                variant="body2"
                                className="chat-username-process"
                            >
                                {props.Amount > 0 ? "Advance" : "Due"}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </ListItem>
            <Divider
                variant="inset"
                component="li"
                sx={{ marginRight: "14px" }}
            />
        </>
    )
}

export default UserList