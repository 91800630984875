import React from 'react'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ForwardIcon from '@mui/icons-material/Forward';
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom"
import { API_URL } from "./../../App";
import { Description } from '../Common';
import dayjs from "dayjs";
import "./PaymentCard.css";

const PaymentCard = (props) => {
    return (
        <Grid container sx={{ display: "inline" }} spacing={2}>
            <List
                sx={{ width: "auto", right: 10 }}
                className={props.PaymentCardType}>
                <ListItem className="cus-box-chat" component={Link} to={props.DelteStatus === "No" ? props.PaymentDetail : null}>
                    <Box sx={{ display: "flex" }}>
                        <IconButton sx={{ padding: "0px 4px" }} aria-label="comment">
                            {props.DelteStatus !== "Yes" ?
                                <ForwardIcon className="payment-icon receiver-icon" />
                                : <DeleteIcon sx={{ color: "#ccc" }} />}
                        </IconButton>

                        {props.DelteStatus === "Yes" ?
                            <Description css={{ alignItem: "center", fontWeight: "600", fontSize: "18px" }} description={
                                props.CustomerType === "customer" && props.status === "given" ? "Credit Deleted"
                                    : props.CustomerType === "customer" && props.status === "received" ? "Payment Deleted"
                                        : props.CustomerType === "supplier" && props.status === "received" ? ""
                                            : props.CustomerType === "supplier" && props.status === "given" ? ""
                                                : props.CustomerType === "supplier" && props.status === "given" ? ""
                                                    : null
                            } />
                            : null}

                        {props.DelteStatus !== "Yes" ?
                            <ListItemText sx={{ color: "#ccc" }}
                                primary={"AED " + props.amount}
                                secondary={dayjs(props.created_dt).format("hh:mm a")}
                                className="payment-icon"
                            />
                            :
                            <ListItemText sx={{ color: "#ccc" }}
                                primary={"AED " + props.amount}
                                className="payment-icon"
                            />
                        }
                        <ListItemText className="payment-check" secondary={<DoneIcon />} />
                    </Box>

                    {props.DelteStatus !== "Yes" ?
                        props.bill !== "" ? (
                            <Box className="bill-img-container">
                                <Avatar
                                    src={`${API_URL}/Upload/PaymentBill/${props.bill}`}
                                    sx={{ width: 200, height: 200, borderRadius: "5px" }}
                                    loading="lazy"
                                />
                            </Box>
                        ) : null
                        : null}

                    <Description description=
                        {props.DelteStatus !== "Yes" ?
                            props.DetailsID === props.p_id ? props.Note : props.p_note
                            : null} css={{ fontSize: "18px", color: "#000" }} />
                </ListItem>
            </List>
        </Grid>
    )
}

export default PaymentCard