import React, { useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { SubHeader } from '../../Components/SubHeader/SubHeader';
import { getCustomerPayment, getCustomerDetails, getUserDetails, getCustomerStatement } from "./ApiCalls"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SouthIcon from '@mui/icons-material/South';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
// import ParLogo from './../../images/par-logo.png'
import { toPng } from "html-to-image";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { API_URL } from "./../../App";
import { Description, Heading } from '../../Components/Common';
import "./CustomerStatement.css"

const CustomerStatement = () => {

    const [open, setOpen] = useState(false);
    const CustomerID = useParams();
    const ref = useRef(null);
    const [FliterType, setFliterType] = useState("overall");

    const TodayDate = dayjs();
    const last7Days = TodayDate.subtract(7, "day");
    const last30Days = TodayDate.subtract(30, "day");

    const [FliterData, setFliterData] = useState("")

    const UserDetails = useQuery("UserDetails", () => {
        return getUserDetails();
    }, {})

    const CustomerAllPayment = useQuery("CustomerPayment", () => {
        return getCustomerPayment(CustomerID);
    }, {})

    const AllCustomer = useQuery("AllCustomer", () => {
        return getCustomerDetails(CustomerID);
    }, {})

    const TotalReceivedArr = [];
    const TotalGivenArr = [];
    const PaymentDateArr = [];

    if (!CustomerAllPayment.isLoading) {
        CustomerAllPayment.data.data.filter((row) => dayjs(row.created_dt) > FliterData).map((row, id) => {
            if (row.status === "received" && row.delete === "No") {
                TotalReceivedArr.push(parseInt(row.amount))
            }
        });
        CustomerAllPayment.data.data.filter((row) => dayjs(row.created_dt) > FliterData).map((row, id) => {
            if (row.status === "given" && row.delete === "No") {
                TotalGivenArr.push(parseInt(row.amount))
            }
        });
        CustomerAllPayment.data.data.filter((row) => dayjs(row.created_dt) > FliterData).map((row, id) => {
            PaymentDateArr.push(row.created_dt)
        });
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#f0f4f5",
            fontWeight: "600",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        backgroundColor: "#fff",
        padding: "10px 15px",
    }));

    const ShareBtn = useCallback(() => {
        setOpen(true)
        if (ref.current === null) {
            return;
        }
        toPng(ref.current, { cacheBust: true })
            .then((dataUrl) => {
                getCustomerStatement(dataUrl, CustomerID)
                    .then((response) => {
                        setOpen(false)
                        console.log(response)
                        console.log(`${API_URL}/Images/customer-statement/${response.data}`)
                        const link = document.createElement('a')
                        link.setAttribute('href', 'whatsapp://send?text=' + encodeURIComponent(`${API_URL}/Images/customer-statement/${response.data}`));
                        link.setAttribute('data-action', 'share/whatsapp/share')
                        link.click()
                    })
            })
            .catch((err) => {
                console.log(err);
            });
    }, [ref]);


    const CustomerStatementTable = () => {
        return (
            <TableContainer component={Paper} className='customer-statement-table'>
                <Table sx={{ minWidth: "100%" }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                {!CustomerAllPayment.isLoading ? "Date" : <Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
                            </StyledTableCell>
                            <StyledTableCell>
                                {!CustomerAllPayment.isLoading ?
                                    <>
                                        Payment (1)<br />
                                        <span className="payment-amt">AED {TotalReceivedArr.reduce((partialSum, a) => partialSum + a, 0)}</span>
                                    </>
                                    :
                                    <>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </>
                                }
                            </StyledTableCell>
                            <StyledTableCell>
                                {!CustomerAllPayment.isLoading ?
                                    <>
                                        Credit (1)<br />
                                        <span className="credit-amt">AED {TotalGivenArr.reduce((partialSum, b) => partialSum + b, 0)}</span>
                                    </>
                                    :
                                    <>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </>
                                }
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!CustomerAllPayment.isLoading ?
                            // CustomerAllPayment.data.data.map((row, id) => {
                            CustomerAllPayment.data.data.filter((row) => dayjs(row.created_dt) > FliterData).map((row, id) => {
                                return (
                                    row.delete === "No" ?
                                        <StyledTableRow key={id}>
                                            <StyledTableCell component="th" scope="row">
                                                <Box className='payment-date-card'>
                                                    {dayjs(row.created_dt).format('MM')}
                                                    <br />
                                                    {dayjs(row.created_dt).format('MMM')}
                                                </Box>
                                            </StyledTableCell>
                                            {row.status === "received" ?
                                                <>
                                                    <StyledTableCell align="center" sx={{ color: "green", fontWeight: "600", display: "flex", alignContent: "center" }}>
                                                        <SouthIcon /> &nbsp; AED {row.amount}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                </>
                                                :
                                                <>
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                    <StyledTableCell align="center" sx={{ color: "red", fontWeight: "600" }}>
                                                        <ArrowUpwardIcon /> AED {row.amount}
                                                    </StyledTableCell>
                                                </>
                                            }
                                        </StyledTableRow>
                                        : null
                                )
                            })
                            :
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    <Box className='payment-date-card' sx={{ bgcolor: "#fff" }}>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} />
                                </StyledTableCell>
                            </StyledTableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer >
        );
    };

    const CustomerStatmentOverview = () => {
        var StartDate = PaymentDateArr[0]
        return (
            !CustomerAllPayment.isLoading ?
                <Box className="amt-and-balance" sx={{ bgcolor: "#fff" }}>
                    <Heading heading={"AED " + (TotalReceivedArr.reduce((partialSum, a) => partialSum + a, 0) - TotalGivenArr.reduce((partialSum, b) => partialSum + b, 0)).toString().replace('-', '')} css={{ my: 1, fontWeight: "600", fontSize: "20px", color: (TotalReceivedArr.reduce((partialSum, a) => partialSum + a, 0) - TotalGivenArr.reduce((partialSum, b) => partialSum + b, 0)) > 0 ? "#008000" : "#ff0000" }} />
                    {PaymentDateArr.length === 1 ?
                        <Description description={"Balance | " + dayjs(StartDate).format('DD MMM YYYY') + " - " + dayjs(StartDate).format('DD MMM YYYY')} />
                        : <Description description={"Balance | " + dayjs(PaymentDateArr[0]).format('DD MMM YYYY') + " - " + dayjs(PaymentDateArr.pop()).format('DD MMM YYYY')} />
                    }
                </Box>
                :
                <Box sx={{ width: "70%", margin: "auto", my: 2, textAlign: "center" }}>
                    <Skeleton variant="text" width={100} sx={{ fontSize: '1rem', margin: "auto" }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </Box>
        )
    }

    return (
        <React.Fragment>
            {!CustomerAllPayment.isLoading ?
                <SubHeader
                    PageHeading="Customer Statement"
                    backto={"/chatpages/" + CustomerID.customer_id}
                    SubTitle={"Current Balance AED " + (TotalReceivedArr.reduce((partialSum, a) => partialSum + a, 0) - TotalGivenArr.reduce((partialSum, b) => partialSum + b, 0)).toString().replace('-', '')}
                />
                :
                <Box sx={{ p: 1, boxShadow: "0px 0px 10px #ccc" }}>
                    <Skeleton animation="wave" variant="rectangular" width="100%" height={15} sx={{ my: 1 }} />
                    <Skeleton animation="wave" variant="rectangular" width="100%" height={15} sx={{ my: 1 }} />
                </Box>
            }
            <Box sx={{ mt: 13, textAlign: 'center' }}>
                <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    <Button className={FliterType === "overall" ? "cs-fliter-card active" : "cs-fliter-card"} variant="outlined" onClick={() => { setFliterType("overall"); setFliterData("") }}>Overall</Button>
                    <Button className={FliterType === "This Month" ? "cs-fliter-card active" : "cs-fliter-card"} variant="outlined" onClick={() => { setFliterType("This Month"); setFliterData(last30Days) }}>This Month</Button>
                    <Button className={FliterType === "Last 7 days" ? "cs-fliter-card active" : "cs-fliter-card"} variant="outlined" onClick={() => { setFliterType("Last 7 days"); setFliterData(last7Days) }}>Last 7 days</Button>
                </Box>
                <CustomerStatmentOverview />
                <CustomerStatementTable />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-around", my: 3 }}>
                <Button
                    color="success"
                    variant="outlined"
                    sx={{ borderRadius: "30px", width: "50%" }}
                    onClick={ShareBtn}
                >
                    <WhatsAppIcon />&nbsp;  Share
                </Button>
            </Box>

            {!UserDetails.isLoading && !AllCustomer.isLoading ?
                <div
                    style={{
                        position: "absolute",
                        left: "-10000px",
                        top: 0,
                    }}
                >
                    <Box ref={ref} sx={{ textAlign: "center", width: "100%", overflow: "hidden" }}>
                        <Box sx={{ backgroundColor: "#dae2e6", width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box sx={{ width: "50%", textAlign: "left", p: 2 }}>
                                <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                    {/* <img src={ParLogo} alt="App Logo" width="60" sx={{ mr: 1 }} /> */}
                                    <span>
                                        <Heading heading={UserDetails.data.data[0].business_name} />
                                        <Description description={UserDetails.data.data[0].u_mob_no} />
                                    </span>
                                </Box>
                                <br />
                                <Description description={UserDetails.data.data[0].address} />
                            </Box>
                            <Box sx={{ width: "50%", textAlign: "right", p: 2 }}>
                                <Description description="Created On" css={{ fontSize: "12px !important", color: "#696969 !important" }} />
                                <Heading heading={dayjs().format("DD MMM YYYY")} />
                                <br />
                                <Description description="Customer" />
                                <Heading heading={AllCustomer.data.data[0].customer_name} css={{ fontSize: "12px !important", color: "#696969 !important" }} />
                                <Description description={AllCustomer.data.data[0].customer_no} css={{ fontSize: "12px !important", color: "#696969 !important" }} />
                            </Box>
                        </Box>
                        <CustomerStatmentOverview />
                        <CustomerStatementTable />
                        <Box sx={{ py: 2, backgroundColor: "#f0f4f5", textAlign: "center", width: "100%" }}>
                            <Typography sx={{ margin: "auto", textAlign: "center",fontSize:"14px !important" }} className="description">{"Current Balance : AED " + (TotalReceivedArr.reduce((partialSum, a) => partialSum + a, 0) - TotalGivenArr.reduce((partialSum, b) => partialSum + b, 0)).toString().replace('-', '') + " As of " + (dayjs().format("DD MMM YYYY"))}</Typography>
                        </Box>
                    </Box>
                </div>
                : null}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    )
}

export default CustomerStatement