import React, { useState, useEffect } from "react";
import ChatPageFooter from "../../Components/ChatPageFooter/ChatPageFooter";
import ChatPageHeader from "../../Components/ChatPageHeader/ChatPageHeader";
import { Box } from "@mui/system";
import InnerChatMain from "../../Components/Innerchat/InnerChatsMain";
import { useParams } from "react-router-dom";
import {
  getDeleteCustomer,
  getcustomerDetails,
  getPayment,
  setPaymentReceived,
  setPayment,
} from "../../Components/Innerchat/ApiCalls";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../Helper/CustomModal/CustomModal";
import { useQuery } from "react-query";
import Skeleton from "@mui/material/Skeleton";
import { getLoginCheck, CheckSecurityPin } from "./../../Auth/Authentication";
import "./ChatPages.css";
import { EditSecurityPinDrawer } from "../../Helper/RightDrawer/RightDrawer";

const ChatPages = () => {
  const navigate = useNavigate();
  const [showComponent, setShowComponent] = useState(false);
  const CustomerID = useParams();
  const [open, setOpen] = useState(false);
  const [Error, setError] = useState("");
  const [ReceivedOpen, setReceivedOpen] = useState(false);
  const [GivenOpen, setGivenOpen] = useState(false);
  const [Status, setStatus] = useState(false);
  const [BtnStatus, setBtnStatus] = useState(true);
  const [SecurityPinDrawer, setSecurityPinDrawer] = useState(false);
  const [SecurityPin, setSecurityPin] = useState("");

  const CustomerDetails = useQuery(
    "CSDetails",
    () => {
      return getcustomerDetails(CustomerID);
    },
    {}
  );

  console.log(CustomerDetails);

  const CustomerPayment = useQuery(
    "AllCustomerPayment",
    () => {
      return getPayment(CustomerID);
    },
    {}
  );

  const PaymentLoader = () => {
    const timer = setTimeout(() => {
      setStatus(false);
      setReceivedOpen(false);
      setGivenOpen(false);
    }, 2500);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    getLoginCheck()
      .then((response) => {
        if (response.data !== "Session found") {
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      setShowComponent(true);
    }, 1000);
  }, []);

  const TotalGivenArr = [];
  const TotalReceivedArr = [];

  if (!CustomerPayment.isLoading) {
    CustomerPayment.data.data.map((row) => {
      if (row.status === "received" && row.delete === "No") {
        TotalReceivedArr.push(parseInt(row.amount));
      }
    });
    CustomerPayment.data.data.map((row) => {
      if (row.status === "given" && row.delete === "No") {
        TotalGivenArr.push(parseInt(row.amount));
      }
    });
  }

  const DeleteCustomer = (event) => {
    event.preventDefault();
    CheckSecurityPin(SecurityPin)
      .then((response) => {
        if (response.data === "Correct Security Pin") {
          setSecurityPinDrawer(false);
          setError("");
          getDeleteCustomer(CustomerID)
            .then((response) => {
              if (response.status === 200) {
                CustomerPayment.refetch();
                CustomerDetails.data.data[0].type === "supplier"
                  ? navigate("/supplier")
                  : navigate("/customer");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setError(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GivenAmount = async (event) => {
    event.preventDefault();
    setBtnStatus(true);
    var data = new FormData(event.currentTarget);
    setPayment(data)
      .then((response) => {
        if (response.status === 200) {
          setStatus(true);
          PaymentLoader();
          CustomerPayment.refetch();
          event.target.reset();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ReceivedAmount = async (event) => {
    event.preventDefault();
    setBtnStatus(true);
    var data = new FormData(event.currentTarget);
    setPaymentReceived(data)
      .then((response) => {
        if (response.status === 200) {
          setStatus(true);
          PaymentLoader();
          CustomerPayment.refetch();
          event.target.reset();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <CustomModal
        open={open}
        onClose={() => setOpen(false)}
        DeleteBtn={() => {
          setSecurityPinDrawer(true);
          setOpen(false);
        }}
      />
      {!CustomerDetails.isLoading && showComponent ? (
        <ChatPageHeader
          CustomerName={CustomerDetails.data.data[0].customer_name}
          CreditPeriod={CustomerDetails.data.data[0].credit_period}
          EmailId={CustomerDetails.data.data[0].customer_email}
          CustomerNo={CustomerDetails.data.data[0].customer_no}
          CustomerStatementPath={
            "/customer-statement/" + CustomerDetails.data.data[0].customer_id
          }
          onclick={() => setOpen(true)}
          CustomerType={CustomerDetails.data.data[0].type}
        />
      ) : (
        <Box className="skeleton-chat-header">
          <Skeleton
            animation="wave"
            variant="circular"
            width={100}
            height={40}
            sx={{ mx: 1 }}
          />
          <Skeleton animation="wave" height={30} width="100%" sx={{ mx: 1 }} />
          <Skeleton
            animation="wave"
            variant="circular"
            width={100}
            height={40}
            sx={{ mx: 1 }}
          />
          <Skeleton
            animation="wave"
            variant="circular"
            width={100}
            height={40}
            sx={{ mx: 1 }}
          />
          <Skeleton
            animation="wave"
            variant="circular"
            width={100}
            height={40}
            sx={{ mx: 1 }}
          />
        </Box>
      )}
      <Box>
        {!CustomerDetails.isLoading &&
        !CustomerPayment.isLoading &&
        showComponent ? (
          <InnerChatMain
            DueAmt={
              TotalReceivedArr.reduce((partialSum, a) => partialSum + a, 0) -
              TotalGivenArr.reduce((partialSum, b) => partialSum + b, 0)
            }
            CustomerName={CustomerDetails.data.data[0].customer_name}
            PaymentGiven={CustomerPayment.data.data}
            CustomerId={CustomerDetails.data.data[0].customer_id}
            CustomerType={CustomerDetails.data.data[0].type}
          />
        ) : (
          <Box className="skeleton-chat-main">
            <Box
              sx={{
                p: 0.3,
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Skeleton
                animation="wave"
                height="60px"
                width="40%"
                sx={{ mx: 1, borderRadius: "25px" }}
              />
            </Box>
            <Box className="customer-sender-skeleton">
              <Box
                sx={{
                  border: "1px solid #ccc",
                  p: 0.5,
                  mr: 1,
                  borderRadius: "5px",
                }}
              >
                <Skeleton animation="wave" height="25px" width={180} />
                <Skeleton animation="wave" height="25px" width={180} />
              </Box>
            </Box>
            <Box className="customer-receiver-skeleton">
              <Box
                sx={{
                  border: "1px solid #ccc",
                  p: 0.5,
                  ml: 1,
                  borderRadius: "5px",
                }}
              >
                <Skeleton animation="wave" height="25px" width={180} />
                <Skeleton animation="wave" height="25px" width={180} />
              </Box>
            </Box>
            <Box className="customer-receiver-skeleton">
              <Box
                sx={{
                  border: "1px solid #ccc",
                  p: 0.5,
                  ml: 1,
                  borderRadius: "5px",
                }}
              >
                <Skeleton animation="wave" height="25px" width={180} />
                <Skeleton animation="wave" height="25px" width={180} />
              </Box>
            </Box>
            <Box className="customer-receiver-skeleton">
              <Box
                sx={{
                  border: "1px solid #ccc",
                  p: 0.5,
                  ml: 1,
                  borderRadius: "5px",
                }}
              >
                <Skeleton animation="wave" height="25px" width={180} />
                <Skeleton animation="wave" height="25px" width={180} />
              </Box>
            </Box>
            <Box className="customer-sender-skeleton">
              <Box
                sx={{
                  border: "1px solid #ccc",
                  p: 0.5,
                  mr: 1,
                  borderRadius: "5px",
                }}
              >
                <Skeleton animation="wave" height="25px" width={180} />
                <Skeleton animation="wave" height="25px" width={180} />
              </Box>
            </Box>
            <Box className="customer-sender-skeleton">
              <Box
                sx={{
                  border: "1px solid #ccc",
                  p: 0.5,
                  mr: 1,
                  borderRadius: "5px",
                }}
              >
                <Skeleton animation="wave" height="25px" width={180} />
                <Skeleton animation="wave" height="25px" width={180} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {!CustomerDetails.isLoading && showComponent ? (
        <ChatPageFooter
          onReceivedSubmit={ReceivedAmount}
          onGivenSubmit={GivenAmount}
          ReceivedOpen={ReceivedOpen}
          GivenOpen={GivenOpen}
          PaymentStatus={Status}
          BtnStatus={BtnStatus}
          OpenReceivedModal={() => {
            setBtnStatus(false);
            setReceivedOpen(true);
          }}
          CloseReceivedModal={() => setReceivedOpen(false)}
          OpenGivenModal={() => {
            setBtnStatus(false);
            setGivenOpen(true);
          }}
          CloseGivenModal={() => setGivenOpen(false)}
          CustomerName={CustomerDetails.data.data[0].customer_name}
          CustomerNo={CustomerDetails.data.data[0].customer_no}
          CustomerId={CustomerDetails.data.data[0].customer_id}
          CustomerType={CustomerDetails.data.data[0].type}
        />
      ) : (
        <Box className="skeleton-chat-footer">
          <Box
            sx={{
              p: 0.3,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Skeleton
              animation="wave"
              height="70px"
              width="100%"
              sx={{ mx: 1, borderRadius: "28px" }}
            />
            <Skeleton
              animation="wave"
              height="70px"
              width="100%"
              sx={{ mx: 1, borderRadius: "28px" }}
            />
          </Box>
        </Box>
      )}
      <EditSecurityPinDrawer
        Title="Enter Security Pin"
        Error={Error}
        open={SecurityPinDrawer}
        value={SecurityPin}
        onChange={setSecurityPin}
        submitform={DeleteCustomer}
        CloseSecurityPinDrawer={() => {
          setSecurityPinDrawer(false);
        }}
      />
    </Box>
  );
};

export default ChatPages;
