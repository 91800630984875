import * as React from "react";
// import OTPInput, { ResendOTP } from "otp-input-react";
import OTPInput from "otp-input-react";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import "./OtpVerify.css";

export default function OtpVerifyPage() {
  const [OTP, setOTP] = useState("");
  // const renderButton = (buttonProps) => {
  //   return <button {...buttonProps}>Resend</button>;
  // };
  // const renderTime = (remainingTime) => {
  //   return <span>{remainingTime} seconds remaining</span>;
  // };
  return (
    <>
      <div className="verifyDiv">
        <p className="verfile-acc">Verify Account</p>
        <p>An OTP has been sent to your entered mobile number </p>
        <div className="otpElements">
          <p className="p3">Enter your Code here</p>
          <div className="verfile-acc">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
              secure
            />
          </div>

          <p>Didn't receive the code?</p>
          {/* <ResendOTP renderButton={renderButton} renderTime={renderTime} /> */}
        </div>
        <Stack>
          <Button
            variant="contained"
            sx={{ width: "90%", margin: "auto", top: "4rem" }}
            component={Link}
          to="/ledger"
          label="Ledger"
          value="ledger"
          >
            Verify
          </Button>
        </Stack>
       
      </div>
    
    </>
  );
}
