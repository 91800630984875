import Axios from 'axios';
import { API_URL } from '../../App';

export const GetSearchCustomer = async () => {
    try {
        const response = await Axios.post(`${API_URL}/customer/get-search-customer`, {
        });
        return response;
    } catch (err) {
        return err;
    }
}