import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Avatar from "@mui/material/Avatar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Stack from "@mui/material/Stack";
import { deepPurple } from "@mui/material/colors";
import CallIcon from "@mui/icons-material/Call";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Drawer from "@mui/material/Drawer";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { Link } from "react-router-dom";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { Heading } from "../Common";
import Dialog from "@mui/material/Dialog";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import MailIcon from '@mui/icons-material/Mail';
import "./ChatPageHeader.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function ChatPageHeader(props) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const list = (anchor) => (
        <Box
            sx={{
                width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
                padding: "30px 0px",
                m: "auto",
            }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <RemoveRoundedIcon className="botton-top-cus" />
            <div className="display-custom">
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 0.5, bgcolor: "#097969" }}
                    className="cus-icons"
                    value={props.CustomerNo}
                    href={`tel:${props.CustomerNo}`}
                >
                    <CallIcon className="icon-btn-cus" />
                </IconButton>
                <span variant="h6">Call</span>
            </div>
            <div className="display-custom">
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 0.5, bgcolor: "#ea80fc" }}
                    className="cus-icons"
                    component={Link}
                    to="/setting"
                    label="help"
                    value="help"
                >
                    <HelpOutlineRoundedIcon className="icon-btn-cus" />
                </IconButton>
                <span variant="h6">Help</span>
            </div>
            <div className="display-custom">
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 0.5, bgcolor: "#ff1744" }}
                    className="cus-icons"
                    onClick={props.onclick}
                >
                    <DeleteOutlineRoundedIcon className="icon-btn-cus" />
                </IconButton>
                <span variant="h6">Delete</span>
            </div>
        </Box>
    );

    return (
        <Box sx={{ flexGrow: 1, boxShadow: 3 }}>
            <AppBar position="static" className="cus-chat-header">
                <Toolbar className="chat-header">
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        component={Link}
                        to={props.CustomerType === "customer" ? "/customer" : "/supplier"}
                        label="Ledger"
                        value="ledger"
                    >
                        <KeyboardBackspaceIcon sx={{ fontSize: "1.8rem" }} />
                    </IconButton>
                    <Avatar
                        alt={props.CustomerName}
                        src="/static/images/avatar/1.jpg"
                        sx={{ bgcolor: deepPurple[500] }}
                    />
                    <Box sx={{ flex: 1, fontSize: "1.2rem" }}>
                        <Heading heading={props.CustomerName} css={{ ml: 1, fontSize: "1.2rem" }} />
                        <Typography sx={{ ml: 1, fontSize: "0.8rem", color: "green" }} onClick={handleClickOpen} variant="h6" gutterBottom>View Profile</Typography>
                    </Box>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 0.5 }}
                        component={Link}
                        to={props.CustomerStatementPath}
                    >
                        <TextSnippetOutlinedIcon sx={{ fontSize: "1.8rem" }} />
                    </IconButton>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 0.5 }}
                        value={props.CustomerNo}
                        href={`tel:${props.CustomerNo}`}
                    >
                        <CallOutlinedIcon sx={{ fontSize: "1.8rem" }} />
                    </IconButton>
                    <div>
                        {["bottom"].map((anchor) => (
                            <React.Fragment key={anchor}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 0.5 }}
                                    onClick={toggleDrawer(anchor, true)}
                                >
                                    <MoreHorizIcon sx={{ fontSize: "1.8rem" }} />
                                </IconButton>
                                <Drawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                >
                                    {list(anchor)}
                                </Drawer>
                            </React.Fragment>
                        ))}
                    </div>
                </Toolbar>
            </AppBar>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Box sx={{}}>
                    <AppBar sx={{ boxShadow: 3 }} className="cus-chat-header">
                        <Toolbar className="chat-header">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Heading
                                heading="Profile"
                                css={{ ml: 2, flex: 1, fontSize: "1.2rem" }}
                            />
                            <IconButton color="inherit">
                                <HelpOutlineOutlinedIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Box sx={{ margin: "5rem auto 2rem auto" }}>
                    <Stack spacing={3} direction="row">
                        <Badge
                            overlap="circular"
                            sx={{}}
                            color="primary"
                            className="photo-icon"
                            badgeContent={<CameraAltOutlinedIcon sx={{ fontSize: "30px" }} />}
                        >
                            <Avatar
                                className="cus-avatar"
                                sx={{ width: "150px", height: "150px" }}
                            />
                        </Badge>
                    </Stack>
                </Box>
                <List sx={{ width: "95%", bgcolor: "background.paper" }}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar sx={{ marginTop: "12px", minWidth: "50px" }}>
                            <AccountCircleOutlinedIcon
                                sx={{ color: "#1976d2", fontSize: "2rem" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body1"
                                        className="profile-subtitle"
                                    >
                                        Name
                                    </Typography>
                                </React.Fragment>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{}}
                                        component="span"
                                        variant="h6"
                                        className="profile-title"
                                    >
                                        {props.CustomerName}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar sx={{ marginTop: "12px", minWidth: "50px" }}>
                            <PhoneAndroidOutlinedIcon
                                sx={{ color: "#1976d2", fontSize: "2rem" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body1"
                                        className="profile-subtitle"
                                    >
                                        Mobile Number
                                    </Typography>
                                </React.Fragment>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="h6"
                                        className="profile-title"
                                    >
                                        {props.CustomerNo}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar sx={{ marginTop: "12px", minWidth: "50px" }}>
                            <CreditCardIcon
                                sx={{ color: "#1976d2", fontSize: "2rem" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body1"
                                        className="profile-subtitle"
                                    >
                                        Credit Period
                                    </Typography>
                                </React.Fragment>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="h6"
                                        className="profile-title"
                                    >
                                        {props.CreditPeriod}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar sx={{ marginTop: "12px", minWidth: "50px" }}>
                            <MailIcon
                                sx={{ color: "#1976d2", fontSize: "2rem" }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body1"
                                        className="profile-subtitle"
                                    >
                                        Email Id
                                    </Typography>
                                </React.Fragment>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="h6"
                                        className="profile-title"
                                    >
                                        {props.EmailId}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </List>
            </Dialog>
        </Box >
    );
}
