import React from 'react'
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { Description, Heading } from "../Common";
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/material';

export const SubHeader = (props) => {
    return (
        <AppBar sx={{ boxShadow: 3 }} className="cus-chat-header">
            <Toolbar className="chat-header">
                <IconButton edge="start" color="inherit" aria-label="close" component={Link} to={props.backto}>
                    <ArrowBackIcon />
                </IconButton>
                <Box sx={{ display: "inline-block" }}>
                    <Heading heading={props.PageHeading} css={{ ml: 2, flex: 1, fontSize: "1.2rem", fontWeight: "600" }} />
                    {props.SubTitle !== "" ?
                        < Description description={props.SubTitle} css={{ ml: 2, flex: 1, fontSize: ".8rem", color: "green" }} />
                        : null}
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export const UserHeader = (props) => {
    return (
        <AppBar sx={{ boxShadow: 3 }} className="cus-chat-header">
            <Toolbar className="chat-header">
                <Avatar alt={props.UserName} src={props.UserProfileImg} sx={{ ml: 2 }} />
                <Box sx={{ display: "inline-block" }}>
                    <Heading
                        heading={props.UserName}
                        css={{ ml: 2, flex: 1, fontSize: "1.2rem", fontWeight: "600", color: "#344767", textTransform: "capitalize" }} />
                </Box>
            </Toolbar>
        </AppBar>
    )
}

