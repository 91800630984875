import Axios from "axios";
import { API_URL } from "../../App";

export const getPaymentDetails = async (PaymentID) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/payment-detail`, {
      PaymentID: PaymentID,
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const UpdatePaymentNoteData = async (note, p_id) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/update-payment-note`, {
      p_note: note,
      p_id: p_id,
    });
    return response;
  } catch (err) {
    return err;
  }
}

export const UpdateAmount = async (data, payment_id) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/update-amount`, {
      amount: data,
      p_id: payment_id,
    });
    return response;
  } catch (err) {
    return err;
  }
}

export const setBillImage = async (data) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/update-bill`, data);
    return response;
  } catch (err) {
    return err;
  }
};

export const getCashReceipt = async (dataUrl, PaymentID) => {
  console.log(PaymentID)
  try {
    const response = await Axios.post(`${API_URL}/payment/send-cash-receipt`, {
      cash_receipt_data: dataUrl,
      payment_id: PaymentID
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const CheckSecurityPin = async (SecurityPin) => {
  try {
    const response = await Axios.post(`${API_URL}/check-security-pin`, {
      security_pin: SecurityPin
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getcustomerDetails = async (customer_id) => {
  try {
    const response = await Axios.post(`${API_URL}/customer/customer-detail`, {
      CustomerID: customer_id,
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getUserDetails = async () => {
  try {
    const response = await Axios.post(`${API_URL}/payment/get-user-details`);
    return response;
  } catch (err) {
    return err;
  }
};
export const DeletePaymentBill = async (p_bill, PaymentID) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/delete-bill`, {
      p_bill: p_bill,
      p_id: PaymentID,
    });
    return response;
  } catch (err) {
    return err;
  }
};