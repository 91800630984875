import React, { useEffect, useState } from 'react'
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { SubHeader } from "./../../Components/SubHeader/SubHeader"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Link } from "react-router-dom";
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { getCustomerOverview } from './ApiCalls'
import "./Account.css";

const Account = () => {

    const [CustomerOverview, setCustomerOverview] = useState([])

    useEffect(() => {
        getCustomerOverview()
            .then((response) => {
                console.log(response.data)
                setCustomerOverview(response.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const CustomerIDArr = [];
    // eslint-disable-next-line array-callback-return
    CustomerOverview.map(row => {
        if (CustomerIDArr.indexOf(row.customer_id) === -1) {
            CustomerIDArr.push(row.customer_id)
        }
    });

    const TotalReceivedArr = [];
    // eslint-disable-next-line array-callback-return
    CustomerOverview.map(row => {
        if (row.status === "received") {
            TotalReceivedArr.push(parseInt(row.amount))
        }
    });

    const TotalGivenArr = [];
    // eslint-disable-next-line array-callback-return
    CustomerOverview.map(row => {
        if (row.status === "given") {
            TotalGivenArr.push(parseInt(row.amount))
        }
    });

    const TotalReceiveAmt = TotalReceivedArr.reduce((partialSum, a) => partialSum + a, 0);
    const TotalGivenAmt = TotalGivenArr.reduce((partialSum, b) => partialSum + b, 0);

    return (
        <React.Fragment>
            <Box sx={{ mt: 12 }}>
                <SubHeader PageHeading="Account" backto="/setting"/>
            </Box>
            <List sx={{ py: 0, margin: "12px 10px", border: "1px solid #ccc", borderRadius: "8px", color: "#3e3e3e !important" }}>
                <ListItem sx={{ display: "grid" }} button  component={Link} to="/account-statement">
                    <h4 className="account-inners">
                        <ImportContactsIcon sx={{ verticalAlign: "middle", mr: 2, color: "#1976d2" }} />Net Balance
                        <KeyboardArrowRightIcon sx={{ verticalAlign: "bottom", right: "15px", position: "absolute", }} />
                    </h4>
                    <h3 className="account-inners-main">
                        Customer Khata
                        <span className="account-inner-icon" style={{ color: (TotalReceiveAmt - TotalGivenAmt) > 0 ? "#008000 " : "#ff0000" }}>{"AED " + (TotalReceiveAmt - TotalGivenAmt).toString().replace('-', '')}</span>
                    </h3>
                    <h4 className="account-inners">
                        <PersonOutlineIcon sx={{ verticalAlign: "sub", mr: 1 }} />
                        {CustomerIDArr.length + " Customer"}
                        <span className="account-inner-icon">{(TotalReceiveAmt - TotalGivenAmt) > 0 ? "You Give" : "You Get"}</span>
                    </h4>
                </ListItem>
            </List>
            {/* <List
                sx={{
                    pt: 0,
                    pb: 0,
                    margin: "12px 10px",
                    border: "1px solid #ccc",
                    borderRadius: "16px",
                    color: "#3e3e3e !important"
                }}
            >
                <ListItem sx={{ display: "grid" }} button>
                    <h4 className="account-inners">
                        <AirportShuttleIcon
                            sx={{ verticalAlign: "middle", mr: 2, color: "#1976d2" }}
                        />
                        Net Balance
                        <span>
                            <KeyboardArrowRightIcon
                                sx={{
                                    verticalAlign: "bottom",
                                    right: "15px",
                                    position: "absolute",
                                }}
                            />
                        </span>
                    </h4>
                    <h3 className="account-inners-main">
                        Supplier Khata
                        <span className="account-inner-icon color-custom">
                            <CurrencyRupeeIcon
                                sx={{
                                    verticalAlign: "middle",
                                    fontSize: "1rem"
                                }}
                            />
                            0
                        </span>
                    </h3>
                    <h4 className="account-inners">
                        <PersonOutlineIcon sx={{ verticalAlign: "sub", mr: 1 }} />1
                        Supplier
                        <span className="account-inner-icon">You Give</span>
                    </h4>
                </ListItem>
            </List> */}
            <List
                sx={{
                    pt: 0,
                    pb: 0,
                    margin: "12px 10px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    color: "#3e3e3e !important"
                }}
            >
                <ListItem sx={{ display: "grid", Size: "18px" }} button>
                    <span className="">
                        <FileDownloadOutlinedIcon
                            sx={{ verticalAlign: "middle", mr: 2, color: "#1976d2" }}
                        />
                        Download Backup
                    </span>
                </ListItem>
            </List>
        </React.Fragment>
    )
}

export default Account