import Axios from 'axios';
import { API_URL } from '../../App';

export const UpdateProfilePicture = async (data) => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/update-profile-picture`, data);
        return response;
    } catch (err) {
        return err;
    }
}
export const getUserDetails = async () => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/get-user-details`);
        return response;
    } catch (err) {
        return err;
    }
}
export const UpdateBusinessName = async (BusinessName) => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/update-business-name`, {
            business_name: BusinessName
        });
        return response;
    } catch (err) {
        return err;
    }
}
export const UpdateAddress = async (Address) => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/update-address`, {
            address: Address
        });
        return response;
    } catch (err) {
        return err;
    }
}
export const UpdateEmail = async (Email) => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/update-email`, {
            email: Email
        });
        return response;
    } catch (err) {
        return err;
    }
}
export const UpdateDescription = async (Description) => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/update-description`, {
            description: Description
        });
        return response;
    } catch (err) {
        return err;
    }
}
export const UpdatePersonName = async (PersonName) => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/update-person-name`, {
            person_name: PersonName
        });
        return response;
    } catch (err) {
        return err;
    }
}
export const UpdateBusinessType = async (BusinessType) => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/update-business-type`, {
            business_type: BusinessType
        });
        return response;
    } catch (err) {
        return err;
    }
}
export const UpdateTrnNumber = async (TrnNumber) => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/update-trn-no`, {
            trn_no: TrnNumber
        });
        return response;
    } catch (err) {
        return err;
    }
}
export const UpdateTlNumber = async (TlNumber) => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/update-tl-no`, {
            tl_no: TlNumber
        });
        return response;
    } catch (err) {
        return err;
    }
}
