import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import "./CustomModal.css";

const CustomModal = (props) => {
  return (
    <Modal open={props.open}>
      <Box className="custom-modal">
        <WarningAmberIcon />
        <Typography className="heading" variant="h6" component="h2">
          Are you sure?
        </Typography>
        <Typography className="description" sx={{ width: "85%", margin: "auto" }}>
          Do you really want to delete these records? This process cannot be
          undone.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            className="cancel-btn"
            sx={{ mx: 1 }}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ mx: 1 }}
            color="error"
            onClick={props.DeleteBtn}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
