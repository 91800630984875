import Axios from 'axios';
import { API_URL } from '../../App';

export const getBusinessCard = async (dataUrl) => {
    try {
        const response = await Axios.post(`${API_URL}/customer/save-business-card`, {
            businesscard_img: dataUrl
        });
        return response;
    } catch (err) {
        return err;
    }
}
export const getUserDetails = async () => {
    try {
        const response = await Axios.post(`${API_URL}/user-profile/get-user-details`);
        return response;
    } catch (err) {
        return err;
    }
}