import React, { useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import dayjs from "dayjs";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import "react-phone-input-2/lib/style.css";
// import PhoneInput from "react-phone-input-2";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Description, Error, Heading } from "./../../Components/Common";
import MailIcon from "@mui/icons-material/Mail";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import DeleteIcon from "@mui/icons-material/Delete";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import OtpInput from "react-otp-input";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { getDeletePayment, CheckSecurityPin } from "./ApiCalls";
import "./RightDrawer.css";
import { PatternFormat } from "react-number-format";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import AueFlag from "../../images/aueflag.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const RightDrawer = (props) => {
  const [CreditPeriod, setCreditPeriod] = React.useState("10  Days");

  const handleChange = (event) => {
    setCreditPeriod(event.target.value);
  };

  return (
    <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
      <Box sx={{ margin: "3rem auto 2rem auto" }}>
        <AppBar sx={{ position: "relative" }} className="cus-chat-header">
          <Toolbar className="chat-header">
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.CloseRightDrawer}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              className="inner-add-customer"
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            >
              {props.Title}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        component="form"
        onSubmit={props.SubmitFrom}
        sx={{ display: "flex", flexWrap: "wrap", m: 3 }}
      >
        <Box sx={{ margin: "auto", textAlign: "center" }}>
          {props.error ? (
            <Alert severity="error">{props.ErrorMsg}</Alert>
          ) : null}
          <br />
          <FormControl sx={{ mb: 4, width: "100%" }}>
            <InputLabel htmlFor="outlined-adornment-amount">Name</InputLabel>
            <OutlinedInput
              required
              id="outlined-adornment-amount"
              name={props.InputUsername}
              startAdornment={
                <InputAdornment position="start">
                  <PersonOutlineIcon />
                </InputAdornment>
              }
              label="Name"
            />
          </FormControl>
          {/* <FormControl sx={{ mb: 1, width: "100%" }}>
            <img src={AueFlag} style={{position:"absolute", padding:"18px 14px" , width:"8%"}} alt=""/>
            <PatternFormat
              type="tel"
              format="971 ## ### ####"
              // mask="_"
              style={{
                height: "1.4375em",
                padding: "16.5px 47px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              value={props.value}
              placeholder="Enter Phone No."
              name={props.InputPhoneNumber}
              onChange={props.ContactFiledChange}
            />
          </FormControl> */}
          <PhoneInput
            country={"ae"}
            value={props.contact_value}
            containerClass="form-control"
            required
            onChange={props.ContactFiledChange}
          />
          <FormControl sx={{ mt: 4, width: "100%" }}>
            <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
            <OutlinedInput
              type="email"
              // required
              name={props.InputEmail}
              startAdornment={
                <InputAdornment position="start">
                  <MailIcon />
                </InputAdornment>
              }
              label="email_id"
            />
          </FormControl>
          <FormControl sx={{ width: "100%", my: 4 }}>
            <InputLabel id="demo-simple-select-label">Credit Period</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={CreditPeriod}
              label="CreditPeriod"
              name="credit_period"
              onChange={handleChange}
            >
              <MenuItem value="10  Days">10 Days</MenuItem>
              <MenuItem value="15 Days">15 Days</MenuItem>
              <MenuItem value="30 Days">30 Days</MenuItem>
              <MenuItem value="45 Days">45 Days</MenuItem>
              <MenuItem value="60 Days">60 Days</MenuItem>
              <MenuItem value="90 Days">90 Days</MenuItem>
            </Select>
          </FormControl>
          <Stack>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "90%", margin: "auto", top: "2rem" }}
            >
              {props.BtnText}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

export const DeleteCreditDrawer = (props) => {
  return (
    <Dialog fullScreen open={props.open} TransitionComponent={Transition}>
      <Box sx={{ margin: "3rem auto 2rem auto" }}>
        <AppBar sx={{ position: "relative" }} className="cus-chat-header">
          <Toolbar className="chat-header">
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.CloseDeleteCreditDrawer}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              className="inner-add-customer"
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            >
              {props.Title}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Card sx={{ p: 2, boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FileUploadIcon sx={{ fontSize: "2.5rem", color: "red" }} />
          <Box>
            <Heading heading="Creadit Given" />
            <Description
              description={
                "Billed On " + dayjs(props.PaymentDate).format("DD MMM YYYY")
              }
            />
          </Box>
          <Heading heading={props.Amount} />
        </Box>
      </Card>
      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Description description="Credit will deleted from account" />
        <Button
          variant="outlined"
          color="error"
          startIcon={<DeleteIcon />}
          sx={{ mt: 3 }}
          onClick={props.DeleteBtnClick}
        >
          Delete
        </Button>
      </Box>
    </Dialog>
  );
};

export const DelSecurityPinDrawer = (props) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [Error, SetError] = useState("");

  const DelectPayment = async (event) => {
    event.preventDefault();
    const PaymentID = props.PaymentId;
    CheckSecurityPin(otp).then((response) => {
      if (response.data !== "Incorrect Security Pin") {
        getDeletePayment(PaymentID).then((response) => {
          if (response.status === 200) {
            navigate("/chatpages/" + props.CustomerID);
          }
        });
        SetError("");
      } else {
        SetError(response.data);
      }
    });
  };

  return (
    <Dialog
      component="form"
      autoComplete="off"
      onSubmit={DelectPayment}
      fullScreen
      open={props.open}
      TransitionComponent={Transition}
    >
      <Box sx={{ margin: "3rem auto 2rem auto" }}>
        <AppBar sx={{ position: "relative" }} className="cus-chat-header">
          <Toolbar className="chat-header">
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.CloseSecurityPinDrawer}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              className="inner-add-customer"
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            >
              {props.Title}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Box className="pin-container">
        {Error !== "" ? (
          <Alert severity="error">Incorrect Security Pin</Alert>
        ) : null}
        <PhonelinkLockIcon
          sx={{ mt: 2, fontSize: "3rem", color: "green", textAlign: "center" }}
        />
        <Heading
          heading="Enter a 4-digit PIN"
          css={{ fontSize: "1.3em", my: 3 }}
        />
        <OtpInput
          value={otp}
          onChange={setOtp}
          inputStyle="inputStyle"
          numInputs={4}
          renderInput={(props) => <input {...props} />}
        />
        <Button type="submit" variant="contained" sx={{ mt: 3 }}>
          Enter
        </Button>
      </Box>
    </Dialog>
  );
};

export const EditSecurityPinDrawer = (props) => {
  return (
    <>
      <Dialog
        component="form"
        autoComplete="off"
        onSubmit={props.submitform}
        fullScreen
        open={props.open}
        TransitionComponent={Transition}
      >
        <Box sx={{ margin: "3rem auto 2rem auto" }}>
          <AppBar sx={{ position: "relative" }} className="cus-chat-header">
            <Toolbar className="chat-header">
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.CloseSecurityPinDrawer}
                aria-label="close"
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                className="inner-add-customer"
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
              >
                Enter Security PIN
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <Box className="pin-container">
          {props.Error !== "" ? (
            <Alert severity="error">{props.Error}</Alert>
          ) : null}
          <PhonelinkLockIcon
            sx={{
              mt: 2,
              fontSize: "3rem",
              color: "green",
              textAlign: "center",
            }}
          />
          <Heading
            heading="Enter a 4-digit PIN"
            css={{ fontSize: "1.3em", my: 3 }}
          />
          <OtpInput
            value={props.value}
            onChange={props.onChange}
            inputStyle="inputStyle"
            numInputs={4}
            inputType="number"
            renderInput={(props) => <input {...props} />}
          />
          <Button type="submit" variant="contained" sx={{ mt: 3 }}>
            Enter
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
