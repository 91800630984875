import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import UserList from "../../Components/UserList/UserList";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { getLoginCheck } from "./../../Auth/Authentication";
import { Heading, AddButton } from "./../../Components/Common";
import { RightDrawer } from "../../Helper/RightDrawer/RightDrawer";
import { getCustomer, getPayment, setCustomer } from "./ApiCalls";
import NoCustomerImg from "./../../images/no-customer.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import "./Customer.css";

const Customer = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, seterror] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [code, setCode] = useState(0);
  const [showComponent, setShowComponent] = useState(false);
  const [number, setNumber] = useState(0);

  const CustomerList = useQuery(
    "CustomerList",
    () => {
      return getPayment();
    },
    {}
  );

  // const AllPayments = useQuery("AllPayment", () => {
  //   return getPayment()
  // }, {})

  const LoginCheck = useQuery(
    "LoginCheckData",
    () => {
      return getLoginCheck();
    },
    {}
  );

  useEffect(() => {
    setTimeout(() => {
      setShowComponent(true);
    }, 11000);
  }, []);

  if (!LoginCheck.isLoading) {
    if (LoginCheck.data.data !== "Session found") {
      navigate("/login");
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const Customer_no = code + number;
    var data = new FormData(event.currentTarget);
    setCustomer(data, Customer_no)
      .then((response) => {
        if (response.data === "Customer Added Successfully") {
          event.target.reset();
          CustomerList.refetch();
          setOpen(false);
          seterror(false);
        } else {
          seterror(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <SearchBar />
      <List
        className="cus-tabpanel"
        sx={{ width: "100%", bgcolor: "background.paper", pt: 0, pb: 0}}
      >
        {CustomerList.isLoading && showComponent ? (
          <Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="80%"
                height={20}
                sx={{ mt: 1 }}
              />
            </Box>
            <Skeleton animation="wave" variant="rectangular" sx={{ mt: 1 }} />
          </Box>
        ) : CustomerList.data !== undefined ? (
          CustomerList.data.length !== 0 ? (
            CustomerList.data.map((row, id) => {
              return (
                <Box key={id}>
                  <UserList
                    redirect={"/chatpages/" + row.customer_id}
                    Amount={row.amounts}
                    name={row.name}
                    date={dayjs(row.create_dt).format("D MMM YYYY")}
                  />
                </Box>
              );
            })
          ) : (
            <Box style={{ textAlign: "center" }}>
              <LazyLoadImage
                alt="No Payment"
                width="80%"
                src={NoCustomerImg}
                effect="blur"
                style={{ margin: "20px 0" }}
              />
              <Heading
                heading="Start your digital ledger by adding a customer"
                css={{ fontSize: "18px", fontWeight: "600" }}
              />
            </Box>
          )
        ) : null}
      </List>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          position: "fixed",
          bottom: "5rem",
          right: "12px",
          padding: "7px 14px",
          borderRadius: "26px",
        }}
      >
        <AddButton primary="Add Customer" onclicknext={() => setOpen(true)} />
      </Stack>
      <RightDrawer
        Title="Add Customer"
        open={open}
        InputUsername="customer_name"
        InputEmail="customer_email"
        error={error}
        ErrorMsg={"Customer Already Exists"}
        InputContactname="customer_no"
        CloseRightDrawer={() => setOpen(false)}
        SubmitFrom={onSubmit}
        BtnText="Add Customer"
        contact_value={code + number}
        ContactFiledChange={
          (value, data, event, formattedValue) =>
            value.slice(data.dialCode.length).length <= 10 ?
              setCode(data.dialCode) + setNumber(value.slice(data.dialCode.length))
              : null
        }
      />
    </React.Fragment>
  );
};

export default Customer;
