import Axios from 'axios';
import { API_URL } from './../../App';

export const LoginUser = async (data) => {
    try {
        const response = await Axios.post(`${API_URL}/login`, {
            username: data.get('username'),
            password: data.get('password')
        });
        return response;
    } catch (err) {
        return err;
    }
}