import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FooterNavbar from "./Components/FooterNavbar/FooterNavbar";
import {
  PaymentDetail,
  ChatPages,
  DownloadReciptUrl,
  Dashboard,
  Profile,
  Search,
  Customer,
  Login,
  Setting,
  PrivacyPolicy,
  OtpVerifyPage,
  CustomerStatement,
  Account,
  AccountStatement,
  Supplier,
} from "./Pages/Pages";
import { QueryClientProvider, QueryClient } from "react-query";
import axios from "axios";
import "./App.css";

export const queryClient = new QueryClient();

// export const API_URL = "http://localhost:3003";
export const API_URL = "https://parbackend.rabs.support";

axios.defaults.withCredentials = true;

const App = () => {
  let href = window.location.href;
  let count = href.split("//").length - 1;

  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");

  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 200);
  }

  return (
    !loading && (
      <QueryClientProvider client={queryClient}>
        <Router>
          {window.location.pathname === "/" || count > 1
            ? (window.location.pathname = "/login")
            : null}
          <Suspense
            fallback={
              <div className="center-body">
                <div className="loader-circle-34"></div>
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<FooterNavbar />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="customer" element={<Customer />} />
                <Route path="supplier" element={<Supplier />} />
                <Route path="setting" element={<Setting />} />
              </Route>
              <Route path="/" element={<Login />} />
              <Route
                path="/payment-detail/:customer_name/:payment_id"
                element={<PaymentDetail />}
              />
              <Route
                path="/customer-statement/:customer_id"
                element={<CustomerStatement />}
              />
              <Route
                exact
                path="/download-recipt/:customer_name/:payment_id"
                element={<DownloadReciptUrl />}
              />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/otpverify" element={<OtpVerifyPage />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/search" element={<Search />} />
              <Route exact path="/chatpages/:id" element={<ChatPages />} />
              <Route path="/account" element={<Account />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/account-statement/:type"
                element={<AccountStatement />}
              />
              <Route path="*" element={<Login />} />
            </Routes>
          </Suspense>
        </Router>
      </QueryClientProvider>
    )
  );
};

export default App;

// import React, { useState } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const images = [
//   {
//     img: "http://placekitten.com/g/400/200",
//     title: "Cat One"
//   },
//   { img: "https://cataas.com/cat", title: "Cat Two" },
//   { img: "http://placekitten.com/g/400/200", title: "Cat Three" },
//   { img: "https://cataas.com/cat/cute", title: "Cat Four" }
// ];

// export default function App() {
// const [selectedImage, setSelectedImage] = useState([]);

//   var settings = {
//     dots: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     beforeChange: (current, next) => {
//       console.log(next);
//       console.log(images[next]);
//       setSelectedImage(images[next]);
//     }
//   };
//   return (
//     <div className="container">
//       <Slider {...settings}>
//         {images.map((image) => (
//           <div style={{ width: "100px", overflow: "hidden" }}>
//             <img alt="kitten" src={image.img} width={100} />
//             <p>{image.title}</p>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// }
