// import dayjs from 'dayjs';
import Axios from "axios";
import { API_URL } from "./../../App";

// const date = dayjs().format('YYYY-MM-DD HH:mm:ss');

export const getDeletePayment = async (PaymentID) => {
  try {
    const response = await Axios.post(`${API_URL}/payment/delete-payment`, {
      PaymentID: PaymentID,
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const CheckSecurityPin = async (SecurityPin) => {
  try {
    const response = await Axios.post(`${API_URL}/check-security-pin`, {
      security_pin: SecurityPin
    });
    return response;
  } catch (err) {
    return err;
  }
};