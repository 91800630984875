import React, { useEffect, useRef, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { API_URL } from "./../../App";
import { toPng } from "html-to-image";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DoneIcon from "@mui/icons-material/Done";
import Skeleton from "@mui/material/Skeleton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Description, Heading } from "./../../Components/Common";
import Avatar from "@mui/material/Avatar";
import {
  getcustomerDetails,
  getPayment,
  getUserDetails,
  getAccountStatement,
} from "./ApiCalls";
import logo from "../../images/par-logo.png";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { SubHeader } from "../../Components/SubHeader/SubHeader";
import "./AccountStatement.css";

const AccountStatement = () => {
  const ref = useRef(null);
  const Type = useParams();
  const [AllCustomer, setAllCustomer] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [AllPayment, setAllPayment] = React.useState([]);

  const AllPaymentsDone = useQuery(
    "PaymentsDone",
    () => {
      return getPayment(Type);
    },
    {}
  );

  const UserDetails = useQuery(
    "PaymentUserDetails",
    () => {
      return getUserDetails();
    },
    {}
  );

  useEffect(() => {
    getcustomerDetails()
      .then((response) => {
        setAllCustomer(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getPayment(Type)
      .then((response) => {
        setAllPayment(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const TotalReceivedArr = [];
  // eslint-disable-next-line array-callback-return
  AllPayment.map((row) => {
    var CustomerName = "";
    AllCustomer.filter((data) => row.customer_id === data.customer_id).map(
      (data, id) => {
        CustomerName = data.customer_name;
      }
    );

    if (
      row.status === "received" &&
      row.delete === "No" &&
      CustomerName !== ""
    ) {
      TotalReceivedArr.push(parseInt(row.amount));
    }
  });

  const TotalGivenArr = [];
  // eslint-disable-next-line array-callback-return
  AllPayment.map((row) => {
    var CustomerName = "";
    AllCustomer.filter((data) => row.customer_id === data.customer_id).map(
      (data, id) => {
        CustomerName = data.customer_name;
      }
    );

    if (row.status === "given" && row.delete === "No" && CustomerName !== "") {
      TotalGivenArr.push(parseInt(row.amount));
    }
  });

  const TotalReceiveAmt = TotalReceivedArr.reduce(
    (partialSum, a) => partialSum + a,
    0
  );
  const TotalGivenAmt = TotalGivenArr.reduce(
    (partialSum, b) => partialSum + b,
    0
  );

  const ShareBtn = useCallback(() => {
    setOpen(true);
    if (ref.current === null) {
      return;
    }
    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        getAccountStatement(dataUrl).then((response) => {
          setOpen(false);
          const link = document.createElement("a");
          link.setAttribute(
            "href",
            "whatsapp://send?text=" +
              encodeURIComponent(
                `${API_URL}/Images/account-statement/${response.data}`
              )
          );
          link.setAttribute("data-action", "share/whatsapp/share");
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  const AccountStatementOverview = () => {
    return (
      <Box sx={{ border: "1px solid #ccc", borderRadius: "10px" }}>
        <Box>
          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: "#fff",
                    color: "#6b6b6b",
                    border: "1px solid #ccc",
                  }}
                >
                  <AccountBalanceWalletIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className="net-balance"
                primary="NetBalance"
                secondary={
                  "AED " +
                  (TotalReceiveAmt - TotalGivenAmt).toString().replace("-", "")
                }
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}>
          <List sx={{ width: "100%" }}>
            <ListItem sx={{ px: 2, borderRight: "1px solid #6b6b6b" }}>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: "#fff",
                    color: "#008000",
                    border: "1px solid #6b6b6b",
                  }}
                >
                  <ArrowDownwardIcon sx={{ fontSize: "25px" }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className="net-balance"
                primary={TotalReceivedArr.length + " payments"}
                secondary={"AED " + TotalReceiveAmt}
              />
            </ListItem>
          </List>
          <List sx={{ width: "100%" }}>
            <ListItem sx={{ px: 2 }}>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: "#fff",
                    color: "#ff0000",
                    border: "1px solid #6b6b6b",
                  }}
                >
                  <ArrowUpwardIcon sx={{ fontSize: "25px" }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className="net-balance-down"
                primary={TotalGivenArr.length + " credits"}
                secondary={"AED " + TotalGivenAmt}
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    );
  };

  const AccountStatementTable = () => {
    return (
      <>
        <table className="account-statement-table">
          <thead>
            <tr>
              <th style={{ fontSize: "12px" }}>DATE</th>
              <th style={{ fontSize: "12px" }}>CUSTOMER NAME</th>
              <th style={{ fontSize: "12px" }}>PAYMENT</th>
              <th style={{ fontSize: "12px" }}>CREDIT</th>
            </tr>
          </thead>
          <tbody>
            {AllPayment.map((row, index) => {
              var CustomerName = "";
              var CustomerNumber = "";
              AllCustomer.filter(
                (data) => row.customer_id === data.customer_id
              ).map((data, id) => {
                CustomerName = data.customer_name;
                CustomerNumber = data.customer_no;
              });
              return row.delete === "No" ? (
                CustomerName !== "" ? (
                  <tr key={index}>
                    <td style={{ fontSize: "12px" }}>
                      <Box className="payment-date">
                        {dayjs(row.created_dt).format("MM")}
                        <br />
                        {dayjs(row.created_dt).format("MMM")}
                      </Box>
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      <Description description={CustomerName} />
                      <br />
                      <Description description={CustomerNumber} />
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      {row.status === "received" ? (
                        <Description description={"AED " + row.amount} />
                      ) : null}
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      {row.status !== "received" ? (
                        <Description description={"AED " + row.amount} />
                      ) : null}
                    </td>
                  </tr>
                ) : null
              ) : null;
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      <SubHeader PageHeading="Account Statement" backto="/dashboard" />
      <Box sx={{ mt: 12, mx: "10px" }}>
        <AccountStatementOverview />
        {!AllPaymentsDone.isLoading ? (
          <Box className="payment-wapper">
            {AllPaymentsDone.data.data.map((row, index) => {
              var CustomerName = "";
              AllCustomer.filter(
                (data) => row.customer_id === data.customer_id
              ).map((data, id) => {
                CustomerName = data.customer_name;
              });
              return row.delete === "No" ? (
                CustomerName !== "" ? (
                  <Grid
                    key={index}
                    className="payment-card"
                    container
                    sx={{
                      display: "inline",
                      textDecoration: "none",
                      color: "#000",
                    }}
                    spacing={2}
                    component={Link}
                    to={"/payment-detail/" + CustomerName + "/" + row.p_id}
                  >
                    <List
                      sx={{ width: "auto", right: 10 }}
                      className={
                        row.status === "given"
                          ? "customer-sender"
                          : "customer-receiver"
                      }
                    >
                      <ListItem className="cus-box-chat">
                        {}
                        <Heading heading={CustomerName} />
                        <Box sx={{ display: "flex" }}>
                          <IconButton
                            sx={{ padding: "0px 4px" }}
                            aria-label="comment"
                          >
                            <ArrowUpwardIcon className="payment-icon receiver-icon" />
                          </IconButton>
                          <ListItemText
                            primary={"AED " + row.amount}
                            secondary={dayjs(row.created_dt).format("hh:mm a")}
                            className="payment-icon"
                          />
                          <ListItemText
                            className="payment-check"
                            secondary={<DoneIcon />}
                          />
                        </Box>
                        {row.p_note !== "" ? (
                          <Description description={row.p_note} />
                        ) : null}
                      </ListItem>
                    </List>
                  </Grid>
                ) : null
              ) : null;
            })}
          </Box>
        ) : (
          <React.Fragment>
            <Box>
              <Box className="customer-sender-skeleton">
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    p: 0.5,
                    mr: 1,
                    borderRadius: "5px",
                  }}
                >
                  <Skeleton animation="wave" height="25px" width={180} />
                  <Skeleton animation="wave" height="25px" width={180} />
                </Box>
              </Box>
              <Box className="customer-receiver-skeleton">
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    p: 0.5,
                    ml: 1,
                    borderRadius: "5px",
                  }}
                >
                  <Skeleton animation="wave" height="25px" width={180} />
                  <Skeleton animation="wave" height="25px" width={180} />
                </Box>
              </Box>
              <Box className="customer-receiver-skeleton">
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    p: 0.5,
                    ml: 1,
                    borderRadius: "5px",
                  }}
                >
                  <Skeleton animation="wave" height="25px" width={180} />
                  <Skeleton animation="wave" height="25px" width={180} />
                </Box>
              </Box>
              <Box className="customer-receiver-skeleton">
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    p: 0.5,
                    ml: 1,
                    borderRadius: "5px",
                  }}
                >
                  <Skeleton animation="wave" height="25px" width={180} />
                  <Skeleton animation="wave" height="25px" width={180} />
                </Box>
              </Box>
              <Box className="customer-sender-skeleton">
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    p: 0.5,
                    mr: 1,
                    borderRadius: "5px",
                  }}
                >
                  <Skeleton animation="wave" height="25px" width={180} />
                  <Skeleton animation="wave" height="25px" width={180} />
                </Box>
              </Box>
              <Box className="customer-sender-skeleton">
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    p: 0.5,
                    mr: 1,
                    borderRadius: "5px",
                  }}
                >
                  <Skeleton animation="wave" height="25px" width={180} />
                  <Skeleton animation="wave" height="25px" width={180} />
                </Box>
              </Box>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <Box sx={{ textAlign: "center", my: 2 }}>
        <Button
          variant="contained"
          startIcon={<WhatsAppIcon />}
          sx={{
            background: "green",
            width: "90%",
            borderRadius: "30px",
            py: 1,
          }}
          onClick={ShareBtn}
        >
          Share
        </Button>
      </Box>
      {!UserDetails.isLoading ? (
        <div
          style={{
            position: "absolute",
            left: "-10000px",
            top: 0,
          }}
        >
          <Box ref={ref} sx={{ bgcolor: "#fff" }}>
            <Box className="account-statment-printview-header">
              <Box sx={{ width: "80%", textAlign: "left", p: 2 }}>
                <Heading heading={UserDetails.data.data[0].business_name} />
                <Description description={UserDetails.data.data[0].address} />
                <Heading heading={UserDetails.data.data[0].u_mob_no} />
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              <Heading
                heading="Account Statement"
                css={{ mb: 2, textAlign: "center" }}
              />
              <AccountStatementOverview />
              <br />
              <AccountStatementTable />
            </Box>
          </Box>
        </div>
      ) : null}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AccountStatement;
