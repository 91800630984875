import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { useQuery } from "react-query";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { deepOrange } from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import WestIcon from "@mui/icons-material/West";
import { GetSearchCustomer } from "./ApiCalls";
import { Link } from "react-router-dom";
import { Heading } from "../../Components/Common";

export default function Search() {
  const [searchValue, setSearchValue] = useState("");
  const [customer, setCustomer] = useState([]);

  useEffect(() => {
    GetSearchCustomer().then((response) => {
      console.log(response.data);
      setCustomer(response.data);
    });
  }, []);

  // const SearchCustomer = (event) => {
  //   console.log(event.target.value);
  //   setCustomer(
  //     customer.filter((item) => {
  //       if (event.target.value === '') {
  //         return item;
  //       } else if (
  //         item.customer_name
  //           .toLowerCase()
  //           .includes(event.target.value.toLowerCase())
  //       ) {
  //         return item;
  //       }
  //     })
  //   );
  // };

  return (
    <Box>
      <Paper
        sx={{
          p: "8px 15px",
          my: 1,
          borderRadius: "initial",
          display: "flex",
          alignItems: "center",
          width: "auto",
        }}
      >
        <IconButton
          component={Link}
          to="/customer"
          sx={{ p: "10px" }}
          aria-label="menu"
        >
          <WestIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Customer"
          value={searchValue}
          // onChange={SearchCustomer}
          onChange={(event) => {
            setSearchValue(event.target.value);
          }}
        />
        <IconButton sx={{ p: "10px" }} aria-label="search">
          <SearchOutlinedIcon />
        </IconButton>
      </Paper>
      {/* {customer.map((message, id) => { */}
      {customer
        .filter((message) => {
          if (searchValue === "") {
            return message;
          } else if (
            message.customer_name
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            return message;
          }
        })
        .map(
          (message, id) => (
            <Box key={id}>
              {console.log(message.customer_id)}
              <ListItem
                button
                alignItems="flex-start"
                component={Link}
                to={"/chatpages/" + message.customer_id}
                label="ChatPages"
                value="chatpages"
                sx={{ pr: "4px" }}
              >
                <ListItemAvatar>
                  <Avatar
                    sx={{ bgcolor: deepOrange[500] }}
                    alt={message.customer_name}
                    src="/static/images/avatar/1.jpg"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        className="chat-username"
                      >
                        {message.customer_name}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        className="chat-username-info"
                      >
                        {message.customer_no}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <Box
                  sx={{
                    bgcolor: "#308e2f",
                    padding: "2px 5px",
                    borderRadius: "5px",
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                >
                  {message.type}
                </Box>
              </ListItem>
              <Divider variant="inset" />
            </Box>
          )
          // ) : (
          //   <h1>Hello World</h1>
          // )
        )}
    </Box>
  );
}
