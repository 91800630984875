import Axios from "axios";
import { API_URL } from "../../App";

export const setCustomer = async (data,Customer_no) => {
  console.log(Customer_no);
  try {
    const response = await Axios.post(`${API_URL}/customer/add-customer`, {
      customer_name: data.get("customer_name"),
      customer_no: Customer_no,
      customer_email: data.get("customer_email"),
      credit_period: data.get("credit_period"),
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const getCustomer = async () => {
  try {
    const response = await Axios.get(`${API_URL}/customer/get-customer`);
    return response;
  } catch (err) {
    return err;
  }
};

export const getPayment = async () => {
  try {
    const response = await Axios.get(`${API_URL}/customer/get-all-payment`);
    const AllCustomer = await Axios.get(`${API_URL}/customer/get-customer`);

    const TotalReceivedArr = [];
    const TotalGivenArr = [];
    var Data = [];

    response.data.map((row) => {
      if (row.status === "received" && row.delete === "No") {
        TotalReceivedArr.push({
          payment: parseInt(row.amount),
          customer_id: row.customer_id,
        });
      }
    });;
    response.data.map((row) => {
      if (row.status === "given" && row.delete === "No") {
        TotalGivenArr.push({
          payment: parseInt(row.amount),
          customer_id: row.customer_id,
        });
      }
    });

    // if (AllCustomer.data.length !== 0) {
      AllCustomer.data.map((message, id) => {
        var TotalGivenCount = 0;
        TotalGivenArr.filter(
          (data) => message.customer_id === data.customer_id
        ).map((data) => {
          TotalGivenCount = TotalGivenCount + parseInt(data.payment);
        });

        var TotalReceivedCount = 0;
        TotalReceivedArr.filter(
          (data) => message.customer_id === data.customer_id
        ).map((data) => {
          TotalReceivedCount = TotalReceivedCount + parseInt(data.payment);
        });
        const TotalAmt = TotalReceivedCount - TotalGivenCount;

        Data.push({
          customer_id: message.customer_id,
          amounts: TotalAmt,
          name: message.customer_name,
          create_dt: message.create_dt
        });

      })
      return Data;
    // } else {
    //   var Data = undefined
    // }

  } catch (err) {
    return err;
  }
};
