import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Typography from "@mui/material/Typography";

export function ButtonSetting(props) {
  return (
    <nav aria-label="main mailbox folders">
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon sx={{ color: "#1976d2", minWidth: "40px" }}>
            {props.icon}
          </ListItemIcon>
          <ListItemText className="custom-btn-label" primary={props.primary} />
        </ListItemButton>
      </ListItem>
    </nav>
  );
}

export function AddButton(props) {
  return (
    <Button
      variant="contained"
      startIcon={<PersonAddIcon />}
      className="add-cus-botton"
      sx={{
        borderRadius: "26px",
        bgcolor: "#d8a24a",
        color: "#fff"
      }}
      onClick={props.onclicknext}
    >
      {props.primary}
    </Button>
  );
}

export const Description = (props) => {
  return (
    <Typography className="description" sx={props.css}>
      {props.description}
    </Typography>
  );
};
export const Error = (props) => {
  return (
    <Typography className="error" sx={props.css}>
      {props.error}
    </Typography>
  );
};

export const Heading = (props) => {
  return (
    <Typography className="heading" sx={props.css} onClick={props.onClick}>
      {props.heading}
    </Typography>
  );
};


