import React, { useCallback, useRef, useState } from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Drawer from '@mui/material/Drawer';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
import { Description, Heading } from '../../Components/Common';
import { Button, Divider } from '@mui/material';
import { toPng } from 'html-to-image';
import { getBusinessCard, getUserDetails } from './ApiCall'
import "./BusinessCardDrawer.css";
import { useQuery } from "react-query";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { API_URL } from '../../App';

const drawerBleeding = 56;

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const BusinessCardDrawer = (props) => {

    const [open, setOpen] = useState(false);

    const UserDetails = useQuery(
        "BusinessCardDetails",
        () => {
            return getUserDetails();
        },
        {}
    );

    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;

    const ref = useRef(null)

    const onButtonClick = useCallback(() => {
        setOpen(true)
        if (ref.current === null) {
            return
        }
        toPng(ref.current, { cacheBust: true, })
            .then((dataUrl) => {
                getBusinessCard(dataUrl)
                    .then((response) => {
                        setOpen(false)
                        console.log(`${API_URL}/Images/BusinessCard/${response.data}`)
                        const link = document.createElement('a')
                        link.setAttribute('href', 'whatsapp://send?text=' + encodeURIComponent(`${API_URL}/Images/BusinessCard/${response.data}`));
                        link.setAttribute('data-action', 'share/whatsapp/share')
                        link.click()
                    })
            })
            .catch((err) => {
                console.log(err)
            })
    }, [ref])

    const [selectedBusinessCard, setSelectedBusinessCard] = useState(0);

    const BusinessCard = [
        { id: 0, bgcolor: "linear-gradient(to right, #c5974e, #c19042)", class: "business-card-0" },
        { id: 1, bgcolor: "linear-gradient(to right, #c80e3d, #ee0c4b)", class: "business-card-0" },
    ];

    var settings = {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => {
            setSelectedBusinessCard(next);
            console.log(selectedBusinessCard)
        }
    };

    return (
        <React.Fragment>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(60% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                        marginTop: "10px"
                    },
                }}
            />
            <Drawer
                container={container}
                anchor="bottom"
                open={props.open}
                onClose={props.onClose}
                ModalProps={{
                    keepMounted: false,
                }}
            >
                <Puller />
                <StyledBox
                    sx={{
                        px: 2,
                        pb: 2,
                        height: '100%',
                        overflow: 'hidden',
                        mt: 2
                    }}
                >
                    <Box sx={{ mt: 1 }}>
                        <Heading heading="Business Card" css={{ fontWeight: "600" }} />
                        <Description description="Expand your business by sharing business card" css={{ fontSize: "12px !important" }} />
                        <Divider sx={{ my: 1 }} />
                    </Box>
                    {!UserDetails.isLoading ?
                        <Box className="business-card-container">
                            <Slider {...settings} style={{ width: "100%", textAlign: "center" }}>
                                {BusinessCard.map((row, id) => (
                                    <Box key={id} className={row.class} ref={selectedBusinessCard === row.id ? ref : null}>
                                        <Description description={UserDetails.data.data[0].business_name} />
                                        <Heading heading={UserDetails.data.data[0].u_ccode + " " + UserDetails.data.data[0].u_mob_no} />
                                        <Heading heading={UserDetails.data.data[0].email} />
                                        <Description description={UserDetails.data.data[0].address} />
                                        <Box className='background'>
                                            <Box className='slice' sx={{ background: row.bgcolor }}></Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Slider>
                            <Box sx={{ width: "100%", textAlign: "center", mt: 2 }}>
                                <Button variant="contained" className='whatsapp-share-btn' onClick={onButtonClick}>Share on whatsapp</Button>
                            </Box>
                        </Box>
                        : null}
                </StyledBox>
            </Drawer>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    )
}

export default BusinessCardDrawer